<template>
  <div :class="'stack-vertical relative card radius type-card-set '+(this.images ? 'with-carousel' : '')" 
    @mouseenter="mouseInside = true" 
    @mouseleave="mouseInside = false" 
    data-gap="none"
    @click="$emit('clicked')">
  	<div v-if="image" class="cover-img radius"><img :src="image"></div>
    <div v-else-if="images" class="cover-img radius relative card-images">
      <base-animated-image
        :images="images"
      />
    </div>
  	<div class="card-text-container radius-bottom">
      <div class="stack-horizontal card-text">
    		<div class="stack-vertical card-text-items">
    			<h4 class="line-clamp">{{title}}</h4>
    			<p class="text--body2">{{subtitle}}</p>
    		</div>
    		<div>
    			<base-icon name="favorite"/>
    		</div>
    	</div>
    </div>
  </div>
</template>

<script>
import classesUtils from '@js/utils/classes.js';
import _ from 'lodash'
export default {
  name: 'BaseCardSet',
  props: {
  	image: String,
  	title: String,
  	subtitle: String,
    images: {
      type: Array,
      required: false
    },
  },
  data() {
  	return {
     mouseInside: false
  	}
  },
  computed: {
    styleImages() {
      let temp = ['bottom', 'top']
      if(this.images.length == 3) temp.push('left')
    }
  },
  methods: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" src="@css/components/cards.scss"></style>
