<template>
  <base-cs
    class="cs-institution"
    v-bind="$props"
    :id="data.id"
    :title="data.title"
    :top="data.top"
    :bottom="data.bottom"
    :image="data.image"
    :skeleton="!loaded || skeleton"
    @clicked="clickHandler"
  />
</template>

<script>
import cardSingle from '@mixins/cardSingle.js';

export default {
  name: 'CardSingleInstitution',
  mixins: [ cardSingle ],
  props: {
    imgAspectRatio: {
      type: String,
      default: "2/1",
    },
    size: {
      type: String,
      default: "medium",
    },
    like: {
      type: Boolean,
      default: false
    },
    skeletonLines: {
      type: Number,
      default: 1,
      required: false
    },
    ignoreLineClamp: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      titleField: "name",
      imageField: ["media_file", "cover_image"] 
    }
  }
}
</script>
