import utils from '@js/utils.js';
import collages from '@js/collages.js';

export default {
  props: {
    item: {
      type: Object
    },
    size: String,
    skeleton: {
      type: Boolean,
      default: false,
      required: false
    },
    ignoreMaxWidth: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      loaded: false,
      placeholder: require('@assets/images/img_placeholder.png'),
      loaderCounter: 0,
      collage: []
    }
  },
  watch: {
    item(n, o) {
      if (n && n.id && this.imageType == "single") {
        this.initLoadEvent();
      }
    }
  },
  computed: {
    data() {
      let rtn = {};
      if (this.item && this.item.id != undefined) {
        this.collage = this.collageType ? this.getCollage({type: this.collageType, name: this.item[this.titleField]}) : undefined;
        rtn["id"] = this.item["id"];
        rtn["title"] = this.item[this.titleField];
        rtn["bottom"] = this.item[this.bottomField];
        rtn["image"] = this.item[this.imageField] ? {url: utils.parseMediaUrl(this.item[this.imageField]), alt: this.item[this.imageField].alternativeText} : this.placeholder;
        rtn["collage"] = this.collage;

      }
      return rtn;
    },
    imagesCount() {
      return this.imageType == "single" ? 1 : 2;
    }
  },
  methods: {
    setLoaded() {
      this.loaderCounter++;
      if (this.loaderCounter >= this.imagesCount) {
        this.data.titleField
        this.loaded = true;
        this.$emit("loaded");        
      }
    },
    clickHandler() {
      // console.log("card clicked, id:", this.data.id);
      this.$emit('clicked', this.data.id);
    },
    initLoadEvent() {
      let that = this;
      let img = [...this.$el.querySelectorAll('img')];
      img.forEach(el => {
        el.addEventListener('load', this.setLoaded);
        el.addEventListener('error', function(img) {
          this.src = that.placeholder;
          that.setLoaded();
        })
      })
    },
    getCollage({type, name}) {
      let rtn = collages[type].find(el => el.name == name).collage;
      return rtn;
    },
    collageLoadHandler() {
      this.setLoaded();
    },
    singleImageLoadHandler() {
      this.setLoaded();
    }
  }
}