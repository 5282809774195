/* 
    SEARCH MODULE

    State of the global search page/functionality
*/

import Vue from 'vue';
import API from '@js/API.js';

export default {
  namespaced: true,
  state: {
    loading: false,
    results: [],
    counts: {
      nEthnographicSources: 0,
      nStories: 0,
      nEvents: 0,
      nInstitutions: 0,
      nArticles: 0
    },
    page: 1,
    pageSize: 27,
    autocomplete: [
      {
        label: 'sheet',
        items: [],
        type: 'EthnographicSource'
      },
      {
        label: 'institution',
        items: [],
        type: 'Institution'
      },
      {
        label: 'database',
        items: [],
        type: 'Database'
      },
      {
        label: 'topic',
        items: [],
        type: 'Topic'
      },
      // {
      //   label: 'event',
      //   items: [],
      //   type: 'Event'
      // },
      // {
      //   label: 'story',
      //   items: [],
      //   type: 'Story'
      // }

    ]
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setResults(state, results) {
      Vue.set(state, "results", results);
    },
    setCount(state, {type, count}) {
      state.counts[type] = count;
    },
    setPage(state, page) {
      state.page = page;
    },
    setAutocomplete(state, {type, items}) {
      let group = state.autocomplete.find(el => el.type == type);
      Vue.set(group, 'items', items.slice(0,4));
    },
    clearAutocomplete(state) {
      Object.keys(state.autocomplete).forEach(el => {
        Vue.set(state.autocomplete[el], 'items', []);
      });
    }
  },
  actions: {
    search({state, commit}, args) {
      args['page'] = state.page;
      commit("setLoading", true)
      return API.backend.get_search_all(args)
                .then(res => {
                  let data = res.data;
                  // console.log("search results: ", data);
                  let rtn = []
                  if (state.page == 1) {
                    let results = data.results[0];
                    Object.keys(results).forEach((key) => {
                      commit("setCount", {type: key, count: results[key]});
                    })
                    rtn = data.json;
                  } else {
                    rtn = [...state.results, ...data.json];
                  }

                  commit("setResults", rtn);
                  commit("setLoading", false);
                })
    },
    getAutocomplete({state, commit}, string) {
      return API.backend.get_autocomplete(string)
                .then(res => {
                  let data = res.data;
                  let values = data.json;
                  state.autocomplete.map(el => el.type).forEach(cl => {
                    let items = values.filter(el => el.class == cl);
                    let group = state.autocomplete.find(el => el.type == cl);
                    commit("setAutocomplete", {type: cl, items: items});
                  })
                })
    }
  },
  getters: {
    count: state => Object.keys(state.counts).map(el => state.counts[el]).reduce((prev, curr) => prev + curr, 0),
    countByType: (state, getters) => type => {
      switch (type) {
        case 'all':
          return getters.count;
          break;
        default: 
          return state.counts[type];
          break;
      }
    },
    autocompleteAvailable: state => state.autocomplete.filter(el => el.items.length)
  }
}