import utils from '@js/utils.js';

export default {
  props: {
    item: {
      type: Object
    },
    type: String,
    imgAspectRatio: String,
    size: String,
    skeleton: {
      type: Boolean,
      default: false,
      required: false
    },
    skeletonLines: {
      type: Number,
      default: 3,
      required: false
    },
    ignoreMaxWidth: {
      type: Boolean,
      default: true,
      required: false
    },
    ignoreLineClamp: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      loaded: false,
      placeholder: require('@assets/images/img_placeholder.png'),
      init: false,
      imgRetry: 0
    }
  },
  watch: {
    item(n, o) {
      if (n.id && !this.init) {
        this.initLoadEvent();
      }
    }
  },
  computed: {
    data() {
      let rtn = {};
      if (this.item && this.item.id) {

        let computedImageField = this.imageField;
        if (this.item.data_type && ['video', 'audio', 'micronarrazione'].indexOf(this.item.data_type.toLowerCase()) >= 0) computedImageField = ["thumb_file"];
        let imageField = null;
        for (let i = 0; i < computedImageField.length; i++) {
          if (this.item[computedImageField[i]]) {
            imageField = computedImageField[i];
            break;
          }
        }

        rtn["id"] = this.item["id"];
        rtn["title"] = this.item[this.titleField];
        rtn["top"] = this.item[this.topField];
        rtn["bottom"] = this.item[this.bottomField];
        rtn["image"] = this.item[imageField] ? utils.parseMediaUrl(this.item[imageField]) : '';

      }
      return rtn;
    }
  },
  methods: {
    setLoaded() {
      this.loaded = true;
      this.$emit("loaded");
    },
    clickHandler() {
      // console.log("card clicked, id:", this.data.id);
      this.$emit('clicked', this.data.id);
    },
    initLoadEvent() {
      this.init = true;
      let that = this;
      let img = this.$el.querySelector('img');
      img.addEventListener('load', this.setLoaded);
      img.addEventListener('error', function(img) {
        // TODO: this is a workaround for missing "is_video" data in sheet API "preview mode"
        if (that.imgRetry > that.imageField.length) {
          this.src = that.placeholder;
          that.setLoaded();
        } else {
          that.imgRetry++;
          this.src = utils.parseMediaUrl(that.item[that.imageField[that.imgRetry]]);
        }        
      })
    }
  },
  mounted() {
    this.initLoadEvent();
  }
}