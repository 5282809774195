<template>
  <div class="cs | img-hover-grow" :class="[{'rounded': type == 'card' || type == 'list', 'skeleton':skeleton, 'no-maxw': ignoreMaxWidth || mobile}, csClass]" :data-cs-size="size" @click="$emit('clicked')">
    
    <div class="cs-img-container | img-hover-grow-container" :class="{'rounded': type != 'card'}" :style="{'aspect-ratio': computedAspectRatio}">
      <img :src="image">
    </div>

    <div class="cs-text-container | text--body2">

      <div class="flow full-width">
        <p v-if="showTop" class="line-clamp">{{top}}</p>
        <h4 :class="{'text--body2-medium': type == 'card', 'line-clamp': !ignoreLineClamp}">{{title}}</h4>
        <p v-if="showBottom" class="line-clamp">{{bottom}}</p>        
      </div>

      <div v-if="like && !skeleton" class="cs-like-container |">
        <base-icon name="favorite" @icon-clicked="" :size="22"/>
      </div>

    </div>


  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'CardSingle',
  props: {
    type: {
      type: String,
      required: false,
      default: 'carousel',
      validator: (val) => ['carousel', 'featured', 'card', 'list']
    },
    imgAspectRatio: {
      type: String,
      required: false,
      default: '1/1',
      validator: (val) => ['1/1', '3/2', '2/1']
    },
    image: {
      type: String, //url
      required: false,
      default: require('@assets/images/img_placeholder.png')
    },
    like: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: (val) => ['large', 'medium', 'small'].indexOf(val) >= 0
    },
    title: {
      type: String,
      required: false
    },
    top: {
      type: String,
      required: false
    },
    bottom: {
      type: String,
      required: false
    },
    skeleton: {
      type: Boolean,
      default: false,
      required: false
    },
    skeletonLines: {
      type: Number,
      default: 3,
      required: false
    },
    ignoreMaxWidth: {
      type: Boolean,
      default: false,
      required: false
    },
    ignoreLineClamp: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    csClass() {
      return `cs-type-${this.type}`;
    },
    computedAspectRatio() {
      return (this.type == 'card' && !this.mobile) ? '1.42' : this.imgAspectRatio;
    },
    showTop() {
      return (this.skeleton && this.skeletonLines > 1) || (this.top && this.top.length);
    },
    showBottom() {
      return (this.skeleton && this.skeletonLines > 2) || (this.bottom && this.bottom.length);
    }
  }
}
</script>

<style lang="scss">
@import '@css/variables.scss';

.cs {
  --flow-space: var(--s0);
  --radius: var(--s1);
  --cs-gap: var(--s1);
  --cs-background: transparent;
  --transition-duration: 300ms;
  --transition-type: ease-out;
  height: fit-content;

  background-color: var(--cs-background);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: var(--cs-gap);
  overflow: hidden;
  transition: background-color var(--transition-duration) var(--transition-type);

  .cs-img-container {
    background-color: var(--placeholder-color);
    flex-shrink: 0;
    isolation: isolate;
  }

  .cs-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    isolation: isolate;
  }

  .cs-text-container {
    display: flex;
    justify-content: space-between;
  }

  .cs-like-container {
    width: 2.25rem; //36px
    padding: 0 var(--s0);
    cursor: pointer;
  }

  &[data-cs-size="large"] {
    max-width: 49rem; /* 784px */
  }
  &[data-cs-size="medium"] {
    max-width: 32rem; /* 512px */
  }
  &[data-cs-size="small"] {
    max-width: 23.5rem; /* 376px */
  }

  &.cs-type-carousel {
  }

  &.cs-type-featured {
    --cs-gap: var(--s2);
    max-width: 56rem; /* 896px */
  }

  &.cs-type-card {
    --cs-gap: 0;
    --cs-text-padding: var(--s2);
    --cs-shadow: var(--shadow-big);
    --cs-background: var(--color-white);
    box-shadow: var(--cs-shadow);
    max-width: 20.4375rem; /* 327px */

    .cs-text-container {
      padding: var(--cs-text-padding);
    }
  }

  &.no-maxw {
    max-width: unset;
  }

  &.cs-type-list {
    --cs-gap: var(--s3);
    flex-direction: row;
    aspect-ratio: 530/257;
    padding: var(--s0);
    max-width: 33.125rem; /* 530 */

    .cs-img-container {
      height: 100%;
      aspect-ratio: 1;
    }
    .cs-text-container {
      flex: 1;
      padding-top: 0.5625rem;
    }
    &:hover {
      --cs-background: var(--color-thin);
    }
  }

  .line-clamp {
    --line-clamp-num: 1;
  }

  &.cs-type-list .line-clamp {
    --line-clamp-num: 4;
  }

}

.cs.skeleton {
  color: transparent;
  &.cs-type-list:hover {
    --cs-background: transparent;
  }
  .cs-img-container img {
    display: none;
  }

  .cs-text-container > div {
    p,
    h4 {
      background-color: var(--placeholder-color);
      min-height: 1em;
      border-radius: 0.3125rem; 
    }
    p:first-child {
      width: 68%;
    }
    h4 {
      width: 88%;
    }
    p:last-child {
      width: 81%;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .cs {
    --radius: var(--s0);

    &[data-cs-size="large"] {
      max-width: unset; //20.9375rem;
    }
    &[data-cs-size="medium"] {
      max-width: unset; //17.3125rem;
    }
    &[data-cs-size="small"] {
      max-width: unset; //17.3125rem;
    }

    &.cs-type-featured {
      --cs-gap: var(--s0);
    }

    &.cs-type-card {
      --cs-text-padding: var(--s1);
      --flow-space: 0px;
      --cs-shadow: var(--shadow-small);
      flex-direction: row;
      aspect-ratio: 335/110;

      .cs-img-container {
        height: 100%;
        aspect-ratio: 1;
      }
      .cs-text-container {
        flex: 1;
      }
    }

    &.cs-type-list {
      padding: 0px;
      --cs-gap: 0.75rem;
    }
  }

  .cs.skeleton {
    &.cs-type-card {
      --flow-space: var(--s0);
    }
  }
}
</style>
