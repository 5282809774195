import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@js/i18nInstance.js';

import { routes, defaultMeta } from '@js/routes.js'; 
// import Home from '@views/ViewHome.vue'
// import Explore from '@views/ViewExplore.vue'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.VUE_APP_BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		// hack found here: https://stackoverflow.com/a/57212324/12920606
		document.getElementById('app').scrollIntoView();
	}
});

router.afterEach(async (to, from) => {
	await Vue.nextTick();

	const lang = i18n.locale;
  const title = (to.meta && to.meta[lang]?.title) ?? to.meta?.title ??
  							defaultMeta[lang]?.title ?? defaultMeta.title;
	const meta = Object.assign({},
	                           defaultMeta.meta, defaultMeta[lang]?.meta,
	                           to.meta?.meta, (to.meta && to.meta[lang]?.meta));

	document.title = title;
	Object.keys(meta).forEach((key) => {
		let elem = document.querySelector(`meta[name="${key}"]`);
		if (elem == null) {
			elem = document.createElement('meta');
			elem.name = key;
			document.head.append(elem);
		}
		elem.content = meta[key];
	});
});

// let ctrlClicked = false;

// window.addEventListener('keydown', function (e) {
//   if(e.key == 'Control') {
//     ctrlClicked = true
//   }
// }, false);

// window.addEventListener('keyup', function (e) {
//   if(e.key == 'Control') {
//     ctrlClicked = false
//   }
// }, false);

// router.beforeEach((to, from, next) => {
//   if (ctrlClicked) {
//     // redirect the user to the login page
//     let routeData = router.resolve({path: to.path});
//     window.open(routeData.href, '_blank');
//     return;
//   }else {
//     next();
//   }
// })


export default router
