<template>
	<div class="filter | stack-horizontal bordered" data-align="center">
		<span class="text--overline2">{{text}}</span>
		<base-icon name="close" :size="16" @icon-clicked="$emit('removed')"/>
	</div>
</template>

<script>
export default {
	name: 'BaseFilter',
	props: {
		text: {
			type: String,
			required: true
		}
	}
}
</script>

<style lang="scss">
.filter {
	width: fit-content;
	padding: var(--s0) var(--s1) var(--s0) var(--s1);
	border-radius: 999px;
	text-transform: uppercase;

	.icon {
		cursor: pointer;
	}
}
</style>