<template>
  <div class="cg" :class="[imageType == 'collage' ? 'collage' : 'img-hover-grow', {'skeleton':skeleton, 'no-maxw': ignoreMaxWidth || mobile}]" :data-cg-size="size" @click="$emit('clicked')">
    
    <div class="cg-img-container | img-hover-grow-container">

      <template v-if="imageType == 'single'">
        <img 
          :src="imageUrl"
          :alt="image.alternativeText"
          @load="singleImageLoadHandler"
          @error="singleImageErrorHandler"
        >
      </template>

      <template v-else-if="imageType == 'collage' && collage.length">
        <img 
          v-for="(im, i) in collage"
          :key="i"
          :src="im.src"
          :alt="im.alternativeText"
          :class="`collage-animation-${im.animation}`"
          @load="collageLoadHandler"
          @error="collageLoadHandler"
        >        
      </template>

    </div>

    <div class="cg-text-container | flow full-width" :class="mobile ? 'text--body2' : 'text--body1'">

      <h4 class="line-clamp">{{title}}</h4>
      <p v-if="showBottom" class="line-clamp">{{bottom}}</p>

    </div>


  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
export default {
  name: 'CardSet',
  props: {
    imageType: {
      type: String,
      required: false,
      default: 'single',
      validator: (val) => ['single', 'collage'].indexOf(val) >= 0
    },
    image: {
      type: Object, //url
    },
    collage: {
      type: Array,
      required: false,
      default: () => []
    },
    size: {
      type: String,
      required: false,
      default: 'standard',
      validator: (val) => ['standard', 'large'].indexOf(val) >= 0
    },
    title: {
      type: String,
      required: false
    },
    bottom: {
      type: String,
      required: false
    },
    skeleton: {
      type: Boolean,
      default: false,
      required: false
    },
    ignoreMaxWidth: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      placeholder: require('@assets/images/img_placeholder.png')
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    showBottom() {
      return (this.skeleton && this.skeletonLines > 2) || (this.bottom && this.bottom.length);
    },
    imageUrl() {
      return this.image.url ? this.image.url : require('@assets/images/img_placeholder.png')
    }
  },
  methods: {
    collageLoadHandler() {
      this.$emit('collage-loaded');
    },
    singleImageLoadHandler() {
      this.$emit('img-loaded');
    },
    singleImageErrorHandler() {
      let img = this.$el.querySelector(".cg-img-container img");
      img.src = this.placeholder;
    }
  }
}
</script>

<style lang="scss">
@import '@css/variables.scss';

.cg {
  --cg-background: var(--color-black);
  --cg-maxw: 23.5rem;
  --cg-text-padding: var(--s2);
  --transition-duration: 150ms;

  cursor: pointer;
  background-color: var(--cg-background);
  aspect-ratio: 2/3;
  max-width: var(--cg-maxw);
  border-radius: var(--radius);
  overflow: hidden;
  color: var(--color-white);
  transition: all var(--transition-duration) var(--transition-type);
  transition-property: box-shadow, transform;

  .cg-img-container {
    aspect-ratio: 1;
    border-radius: var(--radius) var(--radius) 0 0;
  }
  &:not(.skeleton) .cg-img-container {
    background-color: var(--color-ultralight);
  }
  &:not(.collage) .cg-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.collage {
    .cg-img-container {
      position: relative;
      isolation: isolate;
      img {
        isolation: isolate;
        mix-blend-mode: multiply;
        position: absolute;
        width: 110%;
        height: 110%;
        max-width: unset;
        left: calc(-1*5%);
        transition: all var(--transition-duration) var(--transition-type);
        transition-property: top, bottom;

        &.collage-animation-down {
          top: calc(-1*5%);
        }
        &.collage-animation-up {
          bottom: calc(-1*5%);
        }

      }
    }
  }
  &.collage:hover {
    .cg-img-container img {
      &.collage-animation-down {
        top: 0px;
      }
      &.collage-animation-up {
        bottom: 0px;
      }
    }
  }
  .cg-text-container {
    --flow-space: var(--s2);
    padding: var(--cg-text-padding);
  }

  h4.line-clamp {
    --line-clamp-num: 2;
  }
  p.line-clamp {
    --line-clamp-num: 1;
  }

  &:hover {
    box-shadow: var(--shadow-big);
  }

  &.no-maxw {
    max-width: unset;
  }

}

.cg.skeleton {
  color: transparent;
  --cg-background: var(--placeholder-color);
  .cg-img-container img {
    display: none;
  }
  &:hover {
    box-shadow: unset;
  }
}

@media (max-width: 1550px) {
  .cg {
    --base-font-size: 0.8rem;
    --cg-text-padding: 6.4%;
    .cg-text-container {
      --flow-space: var(--s1);
    }
  }
}
@media (max-width: 1000px) {
  .cg {
    --base-font-size: 0.6rem;
    .cg-text-container {
      --flow-space: var(--s0);
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .cg {
    --base-font-size: 1rem;
    --cg-text-padding: 1.25rem;

    .cg-text-container {
      --flow-space: var(--s0);
    }

    h4.line-clamp {
      --line-clamp-num: 2;
    }

    &:hover {
      box-shadow: var(--shadow-small);
    }

    &[data-cg-size="standard"] {
      --cg-maxw: 17.375rem;
    }
    &[data-cg-size="large"] {
      --cg-maxw: 20.875rem;
      --cg-text-padding: var(--s1);
    }
  }

  .cg.skeleton {
  }
}
</style>
