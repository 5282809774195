import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@js/messages.js';

Vue.use(VueI18n)

const lang = navigator.language.split('-')[0];

localStorage.setItem('lang', lang);

const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
  fallbackLocale: 'en'
})

export default i18n;