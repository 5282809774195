const COLOR_NAMES = [
		"black",
		"black-text",
		"gray-ultradark",
        "gray-medium",
		"gray-light",
		"gray-ultralight",
		"white",
		"transparent",
		"primary",
		"primary-125",
		"primary-75",
		"primary-50",
		"primary-25",
		"primary-10",
		"primary-5",
		"dark",
		"light",
		"ultralight",
		"thin"
]

const ICON_NAMES = [
    "account",
    "arrow-left",
    "arrow-move-down",
    "arrow-move-left",
    "arrow-move-right",
    "arrow-move-up",
    "arrow-right-button",
    "arrow-right",
    "breadcrumbs",
    "checkbox-body1-false",
    "checkbox-body1-true",
    "checkbox-body2-false",
    "checkbox-body2-true",
    "close",
    "delete",
    "download",
    "expand-button",
    "favorite",
    "fb",
    "filter",
    "Hybrid-mode",
    "hyperlink",
    "instagram",
    "list-mode",
    "map-mode",
    "menu",
    "radio-btn-01",
    "radio-btn-checked-01",
    "reduce-overline",
    "refresh",
    "search",
    "share",
    "switch-language",
    "tw",
    "view-activate",
    "view-disactivate",
    "zoom-in",
    "zoom-out"
]

const ITALY_REGIONS = [
    "Abruzzo",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia-Romagna",
    "Friuli-Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardegna",
    "Sicilia",
    "Toscana",
    "Trentino-Alto Adige",
    "Umbria",
    "Valle d'Aosta",
    "Veneto"
]

export default {
	color: (val) => {
		return COLOR_NAMES.indexOf(val) >= 0;
	},
    icon: (val) => {
        return ICON_NAMES.indexOf(val) >= 0;
    },
	icon_names: () => ICON_NAMES,
    regions: () => ITALY_REGIONS
}