/* 
	INTERFACE MODULE

	State of the application concerning the user interface
*/

// import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		mobileBrkPoint: 720,
		tabletBrkPoint: 1130,
		mobile: false,
		tablet: false
	},
	mutations: {
		setMobile(state, mobile) {
			state.mobile = mobile;
			// console.log(`This ${state.mobile ? "is" : "isn't"} a mobile device.`)
		},
		setTablet(state, tablet) {
			state.tablet = tablet;
			// console.log(`This ${state.mobile ? "is" : "isn't"} a mobile device.`)
		}
	}
}