<template>
  <div :class="['stack-vertical relative card '+radiusCard+' type-' + type, {skeleton: !loaded}]" @click="$emit('clicked', {id, slug})">
  	<div class="cover-img radius">
      <base-animated-image v-if="images" :images="images" />
      <img v-else :src="image" alt="" :style="{display: loaded ? 'block' : 'none'}">
    </div>
  	<div class="card-text-container full-width">

      <div :class="`stack-horizontal full-width card-text ${isPopup ? 'is-popup' : ''}`" data-justify="spaced" v-if="loaded">
    		<div  class="stack-vertical card-metadata-cont full-width">
  				<p :class="`line-clamp`">{{subtitleTop}}</p>
          <p v-if="isPopup" class="line-clamp capitalize-first text--body1-medium" :style="`--line-clamp-num: ${ mobile ?  1 : 3 }`">{{title}}</p>
    			<h4 v-if="!isPopup" class="line-clamp capitalize-first" :class="{'text--body2-medium': type == 'card'}" :style="`--line-clamp-num: ${ mobile ?  1 : 3 }`">{{title}}</h4>
    			<p v-if="subtitleBottom.length" class="card-subtitle ">{{subtitleBottom}}</p>
          <p v-if="dataType" :class="`card-data-type capitalize-first`">{{dataType}}</p>
    		</div>
    		<div v-if="like" class="icon-container cover">
    			<base-icon name="favorite" hover-cirle @icon-clicked="favouriteClick"/>
    		</div>
    	</div>

      <div v-else class="full-width flow card-text card-text-placeholder" style="--flow-space: var(--s0)" :style="{'padding-left': mobile ? 'var(--s1)' : ''}">
        <p style="width: 50%;" class="text-placeholder"></p>
        <h4 class="text-placeholder full-width"></h4>
        <p style="width: 75%;" class="text-placeholder"></p>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import classesUtils from '@js/utils/classes.js'
import _ from 'lodash';
export default {
  name: 'BaseCardSingle',
  props: {
  	aspectRatio: String,
    id: Number,
    slug: String,
  	image: String,
    images: Array,
  	title: String,
  	subtitleTop: String,
  	subtitleBottom: String,
    dataType: String,
    isPopup: {
    	type: Boolean,
    	default: false
    },
  	like: {
      type: Boolean,
      required: false,
      default: false
    },
  	type: {
      type: String,
      required: false,
      default: 'card'
    },
// Aspect-ratio (1:1, 3:2, 2:1)
// Image
// Title
// Subtitle_top
// Subtitle_bottom
// Like (boolean)
// Type (card, carousel, voice, list-horizontal, list-vertical) 
  },
  data() {
  	return {
      loaded: false,
      placeholder: require('@assets/images/img_placeholder.png')
  	}
  },
  computed: {
    ...mapState('interface',['mobile']),
    computedAspectRatio() {
      return classesUtils.aspectRatioToClass(this.aspectRatio)
    },
    radiusCard() {
      return ['carousel'].indexOf(this.type) > -1 ? 'radius-top' : 'radius'
    }
  },
  methods: {
    setLoaded() {
      this.loaded = true;
    },
    favouriteClick(event) {
      // console.log('favourite click')
      event.stopImmediatePropagation();
    }
  },
  mounted() {
    let that = this;
    let img = this.$el.querySelector('img');
    img.addEventListener('load', this.setLoaded);
    img.addEventListener('error', function(img) {
      this.src = that.placeholder;
      that.setLoaded();
    })
  }
}
</script>

<style lang="scss" src="@css/components/cards.scss"></style>
