<template>
  <base-cs
    class="cs-event"
    v-bind="$props"
    :id="data.id"
    :title="data.title"
    :top="date"
    :bottom="data.bottom"
    :image="data.image"
    :skeleton="!loaded || skeleton"
    @clicked="clickHandler"
  />
</template>

<script>
import cardSingle from '@mixins/cardSingle.js';

export default {
  name: 'CardSingleEvent',
  mixins: [ cardSingle ],
  props: {
    imgAspectRatio: {
      type: String,
      default: "3/2",
    },
    size: {
      type: String,
      default: "large",
    },
    like: {
      type: Boolean,
      default: false
    },
    ignoreLineClamp: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      titleField: "title",
      bottomField: "town",
      imageField: ["media_file", "thumb_file"] 
    }
  },
  computed: {
    date() {
      if (this.item && this.item["date"]) {
        let date = new Date(this.item["date"]);
        return this.item["date"] ? Intl.DateTimeFormat(this.$i18n.locale, { dateStyle: 'long'}).format(date) : undefined;        
      } else {
        return ''
      }
    }    
  }
}
</script>
