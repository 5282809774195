/* 
    ROUTE MODULE

    State of the application concerning the route (url)
*/

import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        query: {}
    },
    mutations: {
        setQueryParam(state, paramObj) {
            Vue.set(state.query, paramObj.key, paramObj.value);
        }
    },
    actions: {
        parseQuery({commit}, args) {
            Object.keys(args).forEach(param => {
                let obj = {
                    key: param,
                    value: args[param]
                }
                commit('setQueryParam', obj);
            })
        }
    },
    getters: {
        currentPage: state => parseInt(state.query.page) || 1
    }
}