import axios from 'axios'
import globals from '@js/globals.js'
import utils from '@js/utils.js'
import _ from 'lodash';

export default {
	mockup: {
		getSearchSheets: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/ethnographic_sources/search/${args.search ? args.search : ''}?${utils.argsToQueryParams(args)}`)
		},
		getGeoData: function() {
			return axios.get(globals.MOCKUP_BACKEND_URL + '/geo_data/')
		},
		getSearchStories: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/stories/?${utils.argsToQueryParams(args)}`)
		},
		getSearchInstitutions: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + '/institutions/?'+utils.argsToQueryParams(args))
		},
		getSearchTopics: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + '/topics/?'+utils.argsToQueryParams(args))
		},
		get_events: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/events/?${utils.argsToQueryParams(args)}`)
					.then((res) => {
						let data = res.data;
						return {
							results: data.count || 0,
							json: data.results || []
						};
					})
		},
		get_events_by_id: function(id) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/events/${id}`)
					.then((res) => {
						let data = res.data;
						return data;
					})
		},
		get_institutions: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/institutions/?${utils.argsToQueryParams(args)}`)
					.then((res) => {
						let data = res.data;
						return {
							results: data.count || 0,
							json: data.results || []
						};
					})
		},
		get_institutions_by_id: function(id) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/institutions/${id}`)
					.then((res) => {
						let data = res.data;
						return data;
					})
		},
		get_stories: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/stories/?${utils.argsToQueryParams(args)}`)
					.then((res) => {
						let data = res.data;
						return {
							results: data.count || 0,
							json: data.results || []
						};
					})
		},
		get_stories_by_id: function(id) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/stories/${id}`)
					.then((res) => {
						let data = res.data;
						return data;
					})
		},
		get_topics: function(args) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/topics/?${utils.argsToQueryParams(args)}`)
					.then((res) => {
						let data = res.data;
						return {
							results: data.length || 0,
							json: data || []
						};
					})
		},
		getSheetById: function(id) {
			return axios.get(globals.MOCKUP_BACKEND_URL + `/ethnographic_sources/${id}`)
		},
	},
	backend: {
		url(endpoint) {
			return globals.BACKEND_URL + globals.API_PATH + '/' + endpoint
		},
		shortUrl() {
			return globals.BACKEND_URL + '/' + endpoint
		},
		getByEndpoint(endpoint, queryObj) {
			queryObj = utils.trimQueryObject(queryObj);
			// console.log('queryObj', queryObj)
			let url = this.url(endpoint)
			// console.log("getByEndpoint",endpoint, "params:", queryObj);
			return axios.get(url, {params: queryObj})
		},
		getFilters(queryObj) {
			return this.getByEndpoint('filters', queryObj);
		},
		getSheetsSearch(queryObj) {
			const lang = localStorage.getItem('lang');
			queryObj.lang = lang;
			return this.getByEndpoint('ethnographic_sources/search', queryObj);			
		},
		getSheetById(id) {
			const lang = localStorage.getItem('lang');
			let url = this.url('ethnographic_sources/find/') + id + `?lang=${lang}`;
			// console.log("getSheetById", url)
			return axios.get(url)
		},
		getSheetBySlug(slug) {
			const lang = localStorage.getItem('lang');
			let url = this.url('ethnographic_sources/find/') + slug + `?lang=${lang}`;
			console.log("getSheetBySlug", url)
			return axios.get(url)
		},
		get_databases: function(args) {
			let url = this.url('databases')
			return axios.get(url + `/?${utils.argsToQueryParams(args)}`)
					.then((res) => {
						let data = res.data;
						// console.log("get_databases response", data)
						return data;
					})
		},
		get_databases_by_id: function(id) {
			let url = this.url('databases')
			return axios.get(url + '/' + id.toString())
					.then((res) => {
						let data = res.data;
						// console.log("get_databases_by_id response", data)
						return data;
					})
		},
		get_topics: function(args) {
			let url = this.url('databases')
			return axios.get(url + `/?${utils.argsToQueryParams(args)}`)
					.then((res) => {
						let data = res.data;
						console.log("get_topics response", data)
						return data;
					})
		},
		get_topics_by_id: function(id) {
			let url = this.url('topics')
			return axios.get(url + '/' + id.toString())
					.then((res) => {
						let data = res.data;
						console.log("get_topics_by_id response", data)
						return data;
					})
		},
		get_institutions: function(args) {
			const lang = localStorage.getItem('lang');
			let url = this.url('institutions')
			return axios.get(url + `/?${utils.argsToQueryParams(args)}&lang=${lang}`)
					.then((res) => {
						let data = res.data;
						// console.log("get_institutions response", data)
						return data;
					})
		},
		get_institutions_by_id: function(id) {
			const lang = localStorage.getItem('lang');
			let url = this.url('institutions')
			return axios.get(url + '/' + id.toString()  + `?lang=${lang}`)
					.then((res) => {
						let data = res.data;
						// console.log("get_institutions_by_id response", data)
						
						// //TODO - WORK WRONG API RESPONSE STRUCTURE OUT
						// data['databases'] = [
						// 	{
								// id: data['database_id'],
								// name: data['database'],
								// media: data['database_media'],
								// sources: data['database_sources']
						// 	}
						// ]
						data.databases.forEach(db => {
							db["id"] = db['database_id'];
							db["name"] = db['database'];
							db["media"] = db['database_media'];
							db["sources"] = db['database_sources'];
						})
						return data;
					})
		},
		get_events: function(args) {
			const lang = localStorage.getItem('lang');
			let url = this.url('events')
			return axios.get(url + `/?${utils.argsToQueryParams(args)}&lang=${lang}`)
					.then((res) => {
						let data = res.data;
						// console.log("get_events response", data)
						return data;
					})
		},
		get_events_by_id: function(id) {
			const lang = localStorage.getItem('lang');
			let url = this.url('events')
			return axios.get(url + '/' + id.toString()  + `?lang=${lang}`)
					.then((res) => {
						let data = res.data;
						// console.log("get_events_by_id response", data)
						return data.json;
					})
		},
		get_stories: function(args) {
			const lang = localStorage.getItem('lang');
			let url = this.url('stories')
			return axios.get(url + `/?${utils.argsToQueryParams(args)}&lang=${lang}`)
				.then((res) => {
					let data = res.data;
					// console.log("get_stories response", data)
					return data;
				});
		},
		get_stories_by_id: function(id) {
			const lang = localStorage.getItem('lang');
			let url = this.url('stories')
			return axios.get(url + '/' + id.toString()  + `?lang=${lang}`)
					.then((res) => {
						let data = res.data;
						// console.log("get_stories_by_id response", data)
						return data.json;
					})
		},
		get_topics: function() {
			let url = this.url('topics')
			return axios.get(url)
					.then((res) => {
						let data = res.data;
						return data;
					})
		},
		get_search_all(queryObj) {
			const lang = localStorage.getItem('lang');
			queryObj.lang = lang;
			return this.getByEndpoint('search_all', queryObj);
		},
		get_autocomplete(string) {
			const lang = localStorage.getItem('lang');
			return this.getByEndpoint('autocomplete', {search: string, lang});
		}
	},
	email: {
		url(action) {
			return globals.EMAIL_URL + '/' +  globals.EMAIL_AUDIENCE_ID + '/' + action
		},
		postSubscriber(email) {
			let url = this.url('members')
			// console.log('url inside api', url, globals.EMAIL_API_KEY)
				// axios.post(url, {
				// 	email_address: email,
				// 	status: 'subscribed'
				// },
				// {
				// 	auth: {
				// 		username: 'anystring',
				// 		password: globals.EMAIL_API_KEY
				// 	}
				// })
			const myHeaders = new Headers();
			myHeaders.append('Accept', 'application/json');
			myHeaders.append('Authorization', 'Basic ' + btoa('anystring' + ":" + globals.EMAIL_API_KEY));

			fetch(url, {
				method: 'POST',
				// headers: myHeaders
				headers: {
					'Accept': 'application/json',
					'Authorization': btoa('anystring:'+ globals.EMAIL_API_KEY),
				}
			})
				.then(res => res.json())
				.then(res => {
					console.log('res', res)
				})
			// return axios.post(url, {
			// 	email_address: email,
			// 	status: 'subscribed'
			// },
			// {
			// 	auth: {
			// 		username: 'anystring',
			// 		password: globals.EMAIL_API_KEY
			// 	}
			// })
		}
	}
}