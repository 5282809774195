import globals from '@js/globals.js';

let areAvifSupported = () => {
	let subStringsArr = window.navigator.userAgent.split(' ')
	subStringsArr = subStringsArr.slice(subStringsArr.findIndex(el => el.indexOf('Gecko') > -1) - subStringsArr.length +1)
	return !subStringsArr.filter(el => {
		let type = el.split('/')[0]
		let numVersion = el.split('/')[1]
		switch (type) {
			case 'Edg':
					return true;
					break;
			case 'Chrome':
				return parseFloat(numVersion) < 85
				break;
			case 'Firefox':
				return parseFloat(numVersion) < 93
				break;
			case 'Version':
				return parseFloat(numVersion) < 16.1
				break;
			case 'CriOS':
				return true;
				break;
			default:
				return false
				break;
		}
	}).length
}

export default {
		isEmpty: function(obj) {
				for(var key in obj) {
						if(obj.hasOwnProperty(key))
								return false;
				}
				return true;
		},
		argsToQueryParams: function(args) {
				if(this.isEmpty(args)) return ''
						
				let params = ''
				Object.keys(args).forEach(k => {
						if(Array.isArray(args[k])) {
								params += k+'='+encodeURIComponent(args[k].join(','))+'&'
						}else {
								params += k+'='+encodeURIComponent(args[k])+'&'
						}
				})
				// console.log(params)
				return params.slice(0,params.length-1)
		},
		isOverflown: (el) => {
			 var curOverflow = el.style.overflow;

			 if ( !curOverflow || curOverflow === "visible" )
					el.style.overflow = "hidden";

			 var isOverflowing = el.clientWidth < el.scrollWidth 
					|| el.clientHeight < el.scrollHeight;

			 el.style.overflow = curOverflow;

			 return isOverflowing;
		},
		parseMediaUrl: urlEnd => {
			return globals.PRODUCTION_BACKEND_URL + urlEnd
		},
		scrollToTop: () => {window.scrollTo(0,0)},
		allEqual: (array) => array.every( (val, i, arr) => val === arr[0] ),
		trimQueryObject: (obj) => {
				let rtn = {}
				Object.keys(obj).forEach(k => {
						if (obj[k] && (!Array.isArray(obj[k]) || obj[k].length)) rtn[k] = obj[k]
				})
				return rtn;
		},
		getBboxByCoords: coords => {
				let bbox = {
						minX: 1000,
						minY: 90,
						maxX: -1000,
						maxY: -90
				}
				coords.forEach(el => {
						if (el[0] != null && el[1] != null && el[0] <= 1000 && el[0] >= -1000 && el[1] <= 90 && el[1] >= -90) {

								bbox.minX = Math.min(bbox.minX, el[0]);
								bbox.minY = Math.min(bbox.minY, el[1]);
								bbox.maxX = Math.max(bbox.maxX, el[0]);
								bbox.maxY = Math.max(bbox.maxY, el[1]);
						}
				});
				return [[bbox.minX, bbox.minY], [bbox.maxX, bbox.maxY]];
		},
		elementsOverlap: (el1, el2) => {
				const domRect1 = el1.getBoundingClientRect();
				const domRect2 = el2.getBoundingClientRect();

				return !(
						domRect1.top > domRect2.bottom ||
						domRect1.right < domRect2.left ||
						domRect1.bottom < domRect2.top ||
						domRect1.left > domRect2.right
				);
		},
		elementsFullyOverlap: (el1, el2) => {
				const domRect1 = el1.getBoundingClientRect();
				const domRect2 = el2.getBoundingClientRect();

				return (
						domRect1.top > domRect2.top &&
						domRect1.right < domRect2.right &&
						domRect1.bottom < domRect2.bottom &&
						domRect1.left > domRect2.left
				);
		},
		beautifyUrl(url) {
				return url.replace('https://www.', '').replace('http://www.', '').replace('https://', '').replace('http://', '');
		},
		getRegionSvgByName(regionName) {
				//maybe a name validation can be useful ...
				try {
						return require('@assets/images/regions/'+regionName.toLowerCase()+'.png');
				} catch {
						return
				}
				
		},
		launchWhenElementBuild(identifier, cb) {
			let interval = setInterval(() => {
				if(document.querySelector(identifier)) {
					clearInterval(interval)
					cb();
				}
			}, 1)
		},
		copyClipboard(link, button, originalContent){
			// link = encodeURIComponent(link)
			navigator.clipboard.writeText(link).then(
				() => {
					/* clipboard successfully set */

					setTimeout(() => {
						// button.querySelector('span:first-child').textContent = originalContent;
						// button.classList.remove('link--copied');
					}, 1500);

				}, () => {
					/* clipboard write failed */
					// button.querySelector('span:first-child').textContent = this.reportShareDialogLinkFailed[this.lan];
					// button.classList.add('link--broken');

					setTimeout(() => {
						// button.querySelector('span:first-child').textContent = originalContent;
						// button.classList.remove('link--broken');
					}, 1500);

				});
		},
		copyLink (e, mobile, title, text, files) {
			let link = window.location.href;
			let button = null;
			// console.log('mobile', mobile, navigator.canShare)
			// let originalContent = button.textContent;
			let originalContent = 'GeCA | Geoportale della Cultura Alimentare: '+title
			// if(!mobile){
				// this.copyClipboard(link, button, originalContent);
			// }else{
				if (navigator.canShare) {
					navigator.share({
						title: originalContent,
						text: location.href.replace(location.hash, '') + '\n' + (text ? text : ''),
						files
					})
					.then(() => {
						console.log('Share was successful.')
						this.copyClipboard(link, button, originalContent);
					})
					.catch((error) => console.log('Sharing failed', error));
				} else {
					this.copyClipboard(link, button, originalContent);
				}
			// }
		},
		navigatorShare: async objShare => {
			if (navigator.canShare) {
				await navigator.share({
					title: objShare.title,
					text: location.href.replace(location.hash, '') + '\n' + objShare.text,
				})

				console.log('Share was successful.')
			} else {

			}
		},
		fromItaDataTypeToTranslateItem: (i18n, itaText) => {
			let mapped = {}

			mapped['immagine'] = 'image'
			mapped['audio'] = 'audio'
			mapped['micronarrazione'] = 'microclips'
			mapped['testo'] = 'text'
			mapped['video'] = 'video'

			return i18n.t('data_types.'+mapped[itaText.toLowerCase()])
		},
		manageImagesExtension: function(url) {return areAvifSupported() ? url.replace(url.split('.')[url.split('.').length-1], 'avif') : url },
		capitalizeFirst: string => string.slice(0,1).toUpperCase() + string.slice(1, string.length),
}