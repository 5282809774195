<template>
  <div id="app">
    <router-view/>
    <interface-cookies/>
  </div>
</template>

<script type="text/javascript">
  import { mapState, mapMutations } from "vuex";
  import InterfaceCookies from '@components/InterfaceCookies.vue';
  export default {
    name: "App",
    components: { InterfaceCookies },
    computed: {
      ...mapState("interface", ["mobile", "tablet", "mobileBrkPoint", "tabletBrkPoint"])
    },
    mounted() {
      console.log(`v${process.env.PACKAGE_VERSION}`);
      window.addEventListener("resize", this.onResize);
      this.onResize();
      const locale = localStorage.getItem('lang');
      if (locale) this.$i18n.locale = locale;
    },
    methods: {
      ...mapMutations("interface",["setMobile", "setTablet"]),
      onResize() {
        if (window.innerWidth <= this.mobileBrkPoint && !this.mobile) {
          this.setMobile(true);
        } else if (window.innerWidth > this.mobileBrkPoint && this.mobile) {
          this.setMobile(false);
        }

        if(window.innerWidth <= this.tabletBrkPoint && !this.tablet) {
          this.setTablet(true);
        } else if (window.innerWidth > this.tabletBrkPoint && this.tablet) {
          this.setTablet(false);
        }
      }
    },
    watch: {
      '$i18n.locale': function(newVal, oldVal) {
        localStorage.setItem('lang', newVal)
      }
    }
  }
</script>
<style src="@/css/main.scss" lang="scss"></style>
