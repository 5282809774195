<template>

    <base-cg
      class="cg-region"
      v-bind="$props"
      :id="data.id"
      :title="data.title"
      :bottom="`${data.bottom} ${$tc('sheet', 2)}`"
      :image-type="imageType"
      :collage="data.collage"
      :skeleton="!loaded || skeleton"
      @clicked="clickHandler"
      @collage-loaded="collageLoadHandler"
    />   

</template>

<script>
import cardSet from '@mixins/cardSet.js';

export default {
  name: 'CardSetRegion',
  mixins: [ cardSet ],
  data() {
    return {
      loaded: false,
      titleField: "name",
      bottomField: "sources",
      collageType: "regions",
      imageType: "collage"
    }
  }
}
</script>
