import utils from '@js/utils.js';

export default {
  topics: [
    {
      name: "Agricoltura",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Agricoltura_1.png"),
          animation: "up",
          alternativeText: 'agricoltura 1'
        },
        {
          src: require("@assets/images/collages/categorie/Agricoltura_2.png"),
          animation: "down",
          alternativeText: 'agricoltura 2'
        },
      ]
    },
    {
      name: "Archivi storici",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Archivi storici_1.png"),
          animation: "up",
          alternativeText: 'archivio storico 1'
        },
        {
          src: require("@assets/images/collages/categorie/Archivi storici_2.png"),
          animation: "down",
          alternativeText: 'archivio storico 2'
        },
      ]
    },
    {
      name: "Cibo e antropologia",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Cibo e antropologia_1.png"),
          animation: "up",
          alternativeText: 'cibo e antropologia 1'
        },
        {
          src: require("@assets/images/collages/categorie/Cibo e antropologia_2.png"),
          animation: "down",
          alternativeText: 'cibo e antropologia 2'
        },
      ]
    },
    {
      name: "Cibo e archeologia",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Cibo e archeologia_1.png"),
          animation: "up",
          alternativeText: 'cibo e archeologia 1'
        },
        {
          src: require("@assets/images/collages/categorie/Cibo e archeologia_2.png"),
          animation: "down",
          alternativeText: 'cibo e archeologia 2'
        },
      ]
    },
    {
      name: "Cibo e gioco",
      featured: true,
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Cibo e gioco_1.png"),
          animation: "up",
          alternativeText: 'cibo e gioco 1'
        },
        {
          src: require("@assets/images/collages/categorie/Cibo e gioco_2.png"),
          animation: "down",
          alternativeText: 'cibo e gioco 2'
        },
      ]
    },
    {
      name: "Cibo e Minoranze linguistiche",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Cibo e Minoranze linguistiche_1.png"),
          animation: "up",
          alternativeText: 'cibo e minorante linguistiche 1'
        },
        {
          src: require("@assets/images/collages/categorie/Cibo e Minoranze linguistiche_2.png"),
          animation: "down",
          alternativeText: 'cibo e minorante linguistiche 2'
        },
      ]
    },
    {
      name: "Cibo e viaggi",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Cibo e viaggi_1.png"),
          animation: "up",
          alternativeText: 'cibo e viaggi 1'
        },
        {
          src: require("@assets/images/collages/categorie/Cibo e viaggi_2.png"),
          animation: "down",
          alternativeText: 'cibo e viaggi 2'
        },
      ]
    },
    {
      name: "Civiltà marinare",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Civiltà marinare_1.png"),
          animation: "up",
          alternativeText: 'civilta marinare 1'
        },
        {
          src: require("@assets/images/collages/categorie/Civiltà marinare_2.png"),
          animation: "down",
          alternativeText: 'civilta marinare 2'
        },
      ]
    },
    {
      name: "Ecomusei / musei etnografici",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Ecomusei musei etnografici_1.png"),
          animation: "up",
          alternativeText: 'ecomusei etnografici 1'
        },
        {
          src: require("@assets/images/collages/categorie/Ecomusei musei etnografici_2.png"),
          animation: "down",
          alternativeText: 'ecomusei etnografici 2'
        },
      ]
    },
    {
      name: "Economia agraria, enogastronomia e sviluppo rurale",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Economia agraria, enogastronomia e sviluppo rurale_1.png"),
          animation: "up",
          alternativeText: 'Economia agraria, enogastronomia e sviluppo rurale 1'
        },
        {
          src: require("@assets/images/collages/categorie/Economia agraria, enogastronomia e sviluppo rurale_2.png"),
          animation: "down",
          alternativeText: 'Economia agraria, enogastronomia e sviluppo rurale 2'
        },
      ]
    },
    {
      name: "Feste popolari",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Feste popolari_1.png"),
          animation: "up",
          alternativeText: 'feste popolari 1'
        },
        {
          src: require("@assets/images/collages/categorie/Feste popolari_2.png"),
          animation: "down",
          alternativeText: 'feste popolari 2'
        },
      ]
    },
    {
      name: "Food Landscape",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Food Landscape_1.png"),
          animation: "up",
          alternativeText: 'food landscape 1'
        },
        {
          src: require("@assets/images/collages/categorie/Food Landscape_2.png"),
          animation: "down",
          alternativeText: 'food landscape 2'
        },
      ]
    },
    {
      name: "Locali storici",
      featured: true,
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Locali storici_1.png"),
          animation: "up",
          alternativeText: 'locali storici 1'
        },
        {
          src: require("@assets/images/collages/categorie/Locali storici_2.png"),
          animation: "down",
          alternativeText: 'locali storici 2'
        },
      ]
    },
    {
      name: "Musica popolare",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Musica popolare_1.png"),
          animation: "up",
          alternativeText: 'musica popolare 1'
        },
        {
          src: require("@assets/images/collages/categorie/Musica popolare_2.png"),
          animation: "down",
          alternativeText: 'musica popolare 2'
        },
      ]
    },
    {
      name: "Narrazioni orali",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Narrazioni orali_1.png"),
          animation: "up",
          alternativeText: 'narrazioni orali 1'
        },
        {
          src: require("@assets/images/collages/categorie/Narrazioni orali_2.png"),
          animation: "down",
          alternativeText: 'narrazioni orali 2'
        },
      ]
    },
    {
      name: "Patrimonio Alimentare UNESCO",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Patrimonio Alimentare UNESCO_1.png"),
          animation: "up",
          alternativeText: 'patrimonio alimentare unesco 1'
        },
        {
          src: require("@assets/images/collages/categorie/Patrimonio Alimentare UNESCO_2.png"),
          animation: "down",
          alternativeText: 'patrimonio alimentare unesco 2'
        },
      ]
    },
    {
      name: "Prodotti tradizionali",
      featured: true,
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Prodotti tradizionali_1.png"),
          animation: "up",
          alternativeText: 'prodotti tradizionali 1'
        },
        {
          src: require("@assets/images/collages/categorie/Prodotti tradizionali_2.png"),
          animation: "down",
          alternativeText: 'prodotti tradizionali 2'
        },
      ]
    },
    {
      name: "Ricette della tradizione",
      featured: true,
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Ricette della tradizione_1.png"),
          animation: "up",
          alternativeText: 'ricette della tradizione 1'
        },
        {
          src: require("@assets/images/collages/categorie/Ricette della tradizione_2.png"),
          animation: "down",
          alternativeText: 'ricette della tradizione 2'
        },
      ]
    },
    {
      name: "Saperi e tecniche",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Saperi e tecniche_1.png"),
          animation: "up",
          alternativeText: 'saperi e tecniche 1'
        },
        {
          src: require("@assets/images/collages/categorie/Saperi e tecniche_2.png"),
          animation: "down",
          alternativeText: 'saperi e tecniche 2'
        },
      ]
    },
    {
      name: "Uomo e animali",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Uomo e animali_1.png"),
          animation: "up",
          alternativeText: 'uomo e animali 1'
        },
        {
          src: require("@assets/images/collages/categorie/Uomo e animali_2.png"),
          animation: "down",
          alternativeText: 'uomo e animali 2'
        },
      ]
    },
    {
      name: "Cibo e musica",
      collage:  [
        {
          src: require("@assets/images/collages/categorie/Cibo e musica_1.png"),
          animation: "up",
          alternativeText: 'cibo e musica 1'
        },
        {
          src: require("@assets/images/collages/categorie/Cibo e musica_2.png"),
          animation: "down",
          alternativeText: 'cibo e musica 2'
        },
      ]
    }
  ],
  regions: [
    {
      name: "Abruzzo",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_abruzzo1.png')),
          animation: "up",
          alternativeText: 'abruzzo 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_abruzzo2.png')),
          animation: "down",
          alternativeText: 'abruzzo 2'
        },
      ]
    },
    {
      name: "Basilicata",
      featured: true,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_basilicata1.png')),
          animation: "up",
          alternativeText: 'basilicata 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_basilicata2.png')),
          animation: "down",
          alternativeText: 'basilicata 2'
        },
      ]
    },
    {
      name: "Calabria",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_calabria1.png')),
          animation: "up",
          alternativeText: 'calabria 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_calabria2.png')),
          animation: "down",
          alternativeText: 'calabria 2'
        },
      ]
    },
    {
      name: "Campania",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_campania1.png')),
          animation: "up",
          alternativeText: 'campania 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_campania2.png')),
          animation: "down",
          alternativeText: 'campania 2'
        },
      ]
    },
    {
      name: "Emilia-Romagna",
      featured: true,
      id: 4,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_emiliaromagna1.png')),
          animation: "up",
          alternativeText: 'emilia romagna 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_emiliaromagna2.png')),
          animation: "down",
          alternativeText: 'emilia romagna 2'
        },
      ]
    },
    {
      name: "Friuli-Venezia Giulia",
      featured: true,
      id: 3,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_friuli1.png')),
          animation: "up",
          alternativeText: 'friuli venezia giulia 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_friuli2.png')),
          animation: "down",
          alternativeText: 'friuli venezia giulia 2'
        },
      ]
    },
    {
      name: "Lazio",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_lazio1.png')),
          animation: "up",
          alternativeText: 'lazio 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_lazio2.png')),
          animation: "down",
          alternativeText: 'lazio 2'
        },
      ]
    },
    {
      name: "Liguria",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_liguria1.png')),
          animation: "up",
          alternativeText: 'liguria 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_liguria2.png')),
          animation: "down",
          alternativeText: 'liguria 2'
        },
      ]
    },
    {
      name: "Lombardia",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_lombardia1.png')),
          animation: "up",
          alternativeText: 'lombardia 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_lombardia2.png')),
          animation: "down",
          alternativeText: 'lombardia 2'
        },
      ]
    },
    {
      name: "Marche",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_marche1.png')),
          animation: "up",
          alternativeText: 'marche 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_marche2.png')),
          animation: "down",
          alternativeText: 'marche 2'
        },
      ]
    },
    {
      name: "Molise",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_molise1.png')),
          animation: "up",
          alternativeText: 'molise 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_molise2.png')),
          animation: "down",
          alternativeText: 'molise 2'
        },
      ]
    },
    {
      name: "Piemonte",
      featured: true,
      id: 2,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_piemonte1.png')),
          animation: "up",
          alternativeText: 'piemonte 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_piemonte2.png')),
          animation: "down",
          alternativeText: 'piemonte 2'
        },
      ]
    },
    {
      name: "Puglia",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_puglia1.png')),
          animation: "up",
          alternativeText: 'puglia 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_puglia2.png')),
          animation: "down",
          alternativeText: 'puglia 2'
        },
      ]
    },
    {
      name: "Sardegna",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_sardegna1.png')),
          animation: "up",
          alternativeText: 'sardegna 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_sardegna2.png')),
          animation: "down",
          alternativeText: 'sardegna 2'
        },
      ]
    },
    {
      name: "Sicilia",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_sicilia1.png')),
          animation: "up",
          alternativeText: 'sicilia 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_sicilia2.png')),
          animation: "down",
          alternativeText: 'sicilia 2'
        },
      ]
    },
    {
      name: "Toscana",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_toscana1.png')),
          animation: "up",
          alternativeText: 'toscana 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_toscana2.png')),
          animation: "down",
          alternativeText: 'toscana 2'
        },
      ]
    },
    {
      name: "Trentino-Alto Adige",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_trentinoaltoadige1.png')),
          animation: "up",
          alternativeText: 'trentino alto adige 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_trentinoaltoadige2.png')),
          animation: "down",
          alternativeText: 'trentino alto adige 2'
        },
      ]
    },
    {
      name: "Umbria",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_umbria1.png')),
          animation: "up",
          alternativeText: 'umbria 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_umbria2.png')),
          animation: "down",
          alternativeText: 'umbria 2'
        },
      ]
    },
    {
      name: "Valle d'Aosta",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_valdaosta1.png')),
          animation: "up",
          alternativeText: 'valle d\aosta 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_valdaosta2.png')),
          animation: "down",
          alternativeText: 'valle d\aosta 2'
        },
      ]
    },
    {
      name: "Veneto",
      featured: false,
      id: 1,
      collage: [
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_veneto1.png')),
          animation: "up",
          alternativeText: 'veneto 1'
        },
        {
          src: require("@assets/images/collages/regioni/"+utils.manageImagesExtension('GECA_veneto2.png')),
          animation: "down",
          alternativeText: 'veneto 2'
        },
      ]
    }
  ]
}