<template>
  <div 
    class="icon cover"
    :class="{ hover: hover, 'hover-cirle': hoverCirle}"
    v-html="svg" 
    :style="` --color-stroke:var(--color-${colorStroke});
              --color-fill:var(--color-${colorFill});
              --color-stroke-hover:var(--color-${colorStrokeHover});
              --color-fill-hover:var(--color-${colorFillHover});
              width: ${size}px; height: ${size}px;`"
    :data-icon-name="name"
    @click="$emit('icon-clicked', $event)">
  </div>
</template>

<script>
import validate from '@js/validations.js';

export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    colorStroke: {
      type: String,
      default: 'transparent',
      validator: validate.color
    },
    colorFill: {
      type: String,
      default: 'black',
      validator: validate.color
    },
    colorStrokeHover: {
      type: String,
      default: 'transparent',
      validator: validate.color
    },
    colorFillHover: {
      type: String,
      default: 'black',
      validator: validate.color
    },
    hover: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 24
    },
    hoverCirle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    svg() {
      return require(`!!html-loader!@assets/icons/${this.name}.svg`)
    }
  },
}
</script>

<style lang="scss">

.icon {
  position: relative;
}
.icon svg {
  z-index: 1;
}
.icon svg path,
.icon svg line,
.icon svg polyline,
.icon svg ellipse,
.icon svg rect,
.icon svg polygon {
  stroke: var(--color-stroke);
  fill: var(--color-fill);
}

.icon.hover:hover svg path,
.icon.hover:hover svg line,
.icon.hover:hover svg polyline,
.icon.hover:hover svg ellipse,
.icon.hover:hover svg rect,
.icon.hover:hover svg polygon {
  stroke: var(--color-stroke-hover);
  fill: var(--color-fill-hover);
}

.icon.hover-cirle:hover:after {
  position: absolute;
  width: 190%;
  height: 190%;
  background-color: var(--color-thin);
  border-radius: 50%;
  content: '';
}

.icon svg {
  width: 100%;
  height: 100%;
}
</style>
