import Vue from 'vue'
import Vuex from 'vuex'
import API from '@js/API.js';
import moduleInterface from "@js/store/store_module_interface.js";
import moduleSheets from "@js/store/store_module_sheets.js";
import moduleRoute from "@js/store/store_module_route.js";
import moduleCms from "@js/store/store_module_cms.js";
import moduleSearch from "@js/store/store_module_search.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	geoData: [],
  	stories: [],
  	numStories: 0,
  	events: [],
  	numEvents: 0,
  	topics: [],
  	databases: [],
  	institutions: [],
  },
  mutations: {
  	setGeoData(state, geoData) {
  		state.geoData = geoData;
  	},
  	setStories(state, stories) {
  		state.stories = stories;
  	},
  	setInstitutions(state, institutions) {
  		state.institutions = institutions;
  	},
  	setTopics(state, topics) {
  		state.topics = topics;
  	},
  	setDatabases(state, databases) {
  		state.databases = databases;
  	},
  	setEvents(state, events) {
  		state.events = events;
  	},
  },
  actions: {
  	getSheetById({commit, state}, {id}) {
  		return (async () => {
			let res = await API.mockup.getSheetById(id)
			commit('setNumSheets', 1);
			commit('setSheets', [res.data])
			return res.data
  		})()
  	},
  	getGeoData({commit, state}) {
  		return (async () => {
			let res = await API.mockup.getGeoData()
			commit('setGeoData', res.data)
			return res.data
  		})()
  	},
  	getSearchStories({commit, state}, args) {
  		return (async () => {
			let res = await API.mockup.getSearchStories(args)
			commit('setStories', res.data.results)
			return res.data.results
  		})()
  	},
  	getSearchIntitutions({commit, state}, args) {
  		return (async () => {
			let res = await API.mockup.getSearchIntitutions(args)
			commit('setInstitutions', res.data.results)
			return res.data
  		})()
  	},
  	getSearchTopics({commit, state}, args) {
  		return (async () => {
			let res = await API.mockup.getSearchTopics(args)
			commit('setTopics', res.data.results)
			return res.data
  		})()
  	},
  	getSearchDatabases({commit, state}, args) {
  		return (async () => {
			let res = await API.mockup.getSearchDatabases(args)
			commit('setDatabases', res.data.results)
			return res.data
  		})()
  	}

  },
  modules: {
  	interface: moduleInterface,
  	sheets: moduleSheets,
  	route: moduleRoute,
  	cms: moduleCms,
  	search: moduleSearch
  },
  getters: {
  	geoData: state => {
  		return state.geoData.filter(el => el.lt && el.lg)
  	}
  }
})
