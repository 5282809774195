import m from '@/static/GeCA_contenuti_statici.json';

function merge_from_json(src, dest, lang) {
  let rtn = {};
  src.forEach(el => {
    rtn[el["Slug"]] = el[`Contenuto ${lang}`];
  })
  return {...dest, ...rtn};
}

let en = {
  sheet: 'source | sources',
  explore: 'explore',
  story: 'story | stories',
  event: 'event | events',
  institution: 'institution | institutions',
  institution_and_partners: 'Institutions and partners',
  database: 'database | databases',
  topic: 'topic | topics',
  take_part: 'take part',
  about: 'about',
  login: 'log in',
  search_all: 'Search',
  search: 'Search',
  press_room: 'press room',
  open_data: 'open data',
  api: 'guidelines and API',
  help: 'help',
  contacts: 'contacts',
  profile: 'Personal profile',
  ordering: {
    relevance: 'Relevance',
    alphaIncreasing: 'A → Z',
    alphaDecreasing: 'Z → A',
    dateIncreasing: 'Date incr.',
    dateDecreasing: 'Date decr.' 
  },
  sheet_town: 'town',
  sheet_province: 'county',
  sheet_region: 'region',
  sheet_institution: 'institution',
  sheet_source_db: 'database',
  sheet_authors: 'authors',
  sheet_archive: 'archive',
  sheet_url: 'source url',
  sheet_source: 'source',
  sheet_rights: 'rights',
  sheet_data_type: 'media type',
  sheet_category: 'category',
  sheet_format: 'format',
  sheet_country: 'nation',
  sheet_geodata_latitude: 'latitude',
  sheet_geodata_longitude: 'longitude',
  sheet_people: 'people',
  sheet_created_at: 'data',
  sheet_material: 'material',
  show_map: 'Show map',
  related_sheets_title: 'explores related elements',
  sheet_related_stories_title: 'stories that contain this sheet',
  see_all: 'See all',
  subscribe: 'Subscribe',
  subscribe_newsletter: 'Subscribe to the newsletter',
  site_index: 'site index',
  terms: 'terms and conditions',
  privacy_policy: 'privacy policy',
  data_types: {
    image: 'image',
    video: 'video',
    audio: 'audio',
    text: 'text',
    model: '3D',
    microclips: 'microclips'
  },
  search_resource_placeholder: 'Look between the sheets in our archives',
  search_resource_placeholder_short: 'Look between the sheets',
  no_results: 'No results',
  events_no_events: 'No upcoming events',
  where: 'where',
  when: 'when',
  from_time: "from",
  to_time: "to",
  no_related_sheets: "No related sheets",
  no_related_stories: "No related stories",
  no_related_databases: "No related database",
  results_for: 'results for',
  partners: 'Partners',
  press_kit: 'Press kit',
  explore_map_loading: 'Loading...',
  copy: 'Copy',
  copied_link: 'Url copied',
  copy_link: 'Copy url',
  download_asset: 'Download',
  downloaded_asset: 'Asset downloaded',
  share: 'Share',
  shared: 'Shared',
  source_url_tooltip: 'source Url'
}

let it = {
  sheet: 'scheda | schede',
  explore: 'esplora',
  story: 'storia | storie',
  event: 'evento | eventi',
  institution: 'ente | enti',
  institution_and_partners: 'Enti e partner',
  database: 'database | databases',
  topic: 'topic | topics',
  take_part: 'partecipa',
  about: 'about',
  login: 'accedi',
  search_all: 'Cerca nel sito',
  search: 'Cerca',
  press_room: 'press room',
  open_data: 'open data',
  api: 'linee guida e API',
  help: 'help',
  contacts: 'contatti',
  profile: 'profilo personale',
  ordering: {
    relevance: 'Rilevanza',
    alphaIncreasing: 'A → Z',
    alphaDecreasing: 'Z → A',
    dateIncreasing: 'Data cresc.',
    dateDecreasing: 'Data decresc.' 
  },
  sheet_town: 'città',
  sheet_province: 'provincia',
  sheet_region: 'regione',
  sheet_institution: 'ente',
  sheet_source_db: 'database',
  sheet_authors: 'autori',
  sheet_archive: 'archivio',
  sheet_url: 'url sorgente',
  sheet_source: 'sorgente',
  sheet_rights: 'diritti',
  sheet_data_type: 'tipologia media',
  sheet_category: 'categoria',
  sheet_format: 'format',
  sheet_country: 'nazione',
  sheet_geodata_latitude: 'latitudine',
  sheet_geodata_longitude: 'longitudine',
  sheet_people: 'persone',
  sheet_reated_at: 'data',
  sheet_material: 'materiale',
  show_map: 'Mostra la mappa',
  related_sheets_title: 'esplora gli elementi correlati',
  sheet_related_stories_title: 'storie in cui compare la scheda',
  see_all: 'Vedi tutte',
  subscribe: 'Iscriviti',
  subscribe_newsletter: 'Iscriviti alla newsletter',
  site_index: 'indice sito',
  terms: 'termini e condizioni',
  privacy_policy: 'privacy policy',
  data_types: {
    image: 'immagine',
    video: 'video',
    audio: 'suono',
    text: 'testo',
    model: '3D',
    microclips: 'micronarrazioni'
  },
  search_resource_placeholder: 'Cerca tra le schede dei nostri archivi',
  search_resource_placeholder_short: 'Cerca tra le schede',
  no_results: 'Nessun risultato',
  events_no_events: 'Nessun evento in programma',
  where: 'dove',
  when: 'quando',
  from_time: "dalle ore",
  to_time: "alle ore",
  no_related_sheets: "Nessuna scheda correlata",
  no_related_stories: "Nessuna storia correlata",
  no_related_databases: "Nessun database correlato",
  results_for: 'risultati per',
  partners: 'Partner',
  press_kit: 'Press kit',
  explore_map_loading: 'Caricamento...',
  copied_link: 'Url copiato',
  copy: 'Copia',
  copy_link: 'Copia url',
  download_asset: 'Scarica',
  downloaded_asset: 'Media scaricato',
  share: 'Condividi',
  shared: 'Condivisione avvenuta',
  source_url_tooltip: 'Url sorgente'
}

en = merge_from_json(m, en,"ENG")
it = merge_from_json(m, it,"ITA")

export default {en, it}