export default {
	aspectRatioToClass: function(ratio) {
		switch (ratio) {
			case '1:1':
				return 'one-one'
				break;
			case '3:2':
				return 'three-two'
				break;
			case '2:1':
				return 'two-one'
				break;
		}
	}
}