<template>
  <div class="video-container relative stack-vertical">
		<!-- <img :src="defIcon" class="video-icon" :style="'opacity: ' + (showIcon ? '1' : '0')" @click="handlePlayPause"> -->
		<video class="story-video" controls playsinline :poster="placeholder">
			<source :src="source" type="">
		</video>
    <div v-if="title" class="text--body2 video-title">{{title}}</div>
  </div>
</template>

<script>
import mockup from '@mockup/mockup_data.js'
export default {
  name: 'BaseVideo',
  props: {
  	source: String,
    title: String,
    placeholder: {
      type: String,
      required: false,
      default: require('@assets/images/video_placeholder.png')
    }
  },
  data() {
  	return {
  		playIcon: require('@assets/icons/play_video.svg'),
  		stopIcon: require('@assets/icons/stop_video.svg'),
  		pauseIcon: require('@assets/icons/pause-button-svgrepo-com.svg'),
  		showIcon: false,
      isPlaying: false,
  	}
  },
  computed: {
  	defIcon() {
  		return this.isPlaying ? this.pauseIcon : this.playIcon;
  	}
  },
  methods: {
  	handlePlayPause() {
  		if(document.querySelector('.story-video').playing) {
  			document.querySelector('.story-video').pause()
        this.isPlaying = false
  		}else {
  			document.querySelector('.story-video').play()
        this.isPlaying = true
  		}

  	}
  },
  mounted() {
  	document.querySelector('.video-container').onmouseenter = e => {
  		// console.log('inside')
  		this.showIcon = true;
  	}

  	document.querySelector('.video-container').onmouseleave = e => {
  		// console.log('outside')
  		this.showIcon = false;
  	}
  }
}
</script>

<style lang="scss">
	@import '@css/variables.scss';

  .video-container {
    --flow-space-horizontal: var(--s2);
  }

  .video-title {
    // color: var(--color-primary-50);
    // font-size: var(--base-font-size);
  }

	.story-video {
		width: 100%;
		height: auto;
	}

	.video-icon {
		position: absolute; 
		left: 50%; 
		top: 50%; 
		width: var(--s4);
		height: var(--s4);
		transform: translate(-50%, -50%);
		z-index: 1;
    cursor: pointer;
    transition: opacity $transition-speed;
	}
</style>
