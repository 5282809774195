<template>
	<div class="cookies-item-wrapper full-width stack-horizontal" data-align="center" data-justify="center">
		<div class="cookies-item hide">
			<div class="stack-horizontal cookies-container big-shady">
				<div>{{$t('cookies_text')}}</div>
				<div class="cookies-buttons-container stack-horizontal" data-align="center">
					<base-button :text="$t('cookies_button_info')" type="tertiary" @clicked="openInfo" :fit-width="mobile" />
					<base-button :text="$t('global_bottone_chiudi')" type="secondary" @clicked="accept" :fit-width="mobile"  />
				</div>
			</div>		
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'InterfaceCookies',
	data() {
		return {
		}
	},
	computed:{
		...mapState({
			tablet: state => state.interface.tablet,
			mobile: state => state.interface.mobile
		})
	},
	methods: {
		accept() {
			// console.log('accept cookies')
			document.querySelector('.cookies-item-wrapper').classList.remove('appear')
		  let d = new Date();
		  let exdays = 0.3;
		  this.setCookie('geca', '1', 30);
		  setTimeout(() => {
		  	document.querySelector('.cookies-item-wrapper').style.display = 'none'
		  }, 1000)
		},
		appear() {
			document.querySelector('.cookies-item-wrapper').classList.add('appear')
		},
		openInfo() {
			window.open('https://www.beniculturali.it/cookie-policy', '_blank');
		},
		setCookie(cname, cvalue, exdays) {
		  let expires;
		  if (typeof exdays !== 'undefined') {
		    const d = new Date();
		    d.setTime(d.getTime() + (exdays*24*60*60*1000));
		    expires = ";expires="+ d.toUTCString();
		  } else {
		    expires = "";
		  }
		  document.cookie = cname + "=" + cvalue + expires + ";path=/";
		},
		getCookie(cname) {
		  let name = cname + "=";
		  let decodedCookie = decodeURIComponent(document.cookie);
		  let ca = decodedCookie.split(';');
		  for(let i = 0; i <ca.length; i++) {
		    let c = ca[i];
		    while (c.charAt(0) == ' ') {
		      c = c.substring(1);
		    }
		    if (c.indexOf(name) == 0) {
		      return c.substring(name.length, c.length);
		    }
		  }
		  return "";
		}
	},
	mounted() {
		if(!this.getCookie('geca')) {
			setTimeout(() => {
				this.appear();	
			}, 3000)
			// document.querySelector('.cookies-item').ontransitionend = e => {
			// 	if(e.target.classList.contains('appear')) {
			// 		document.querySelector('.cookies-item').style.zIndex = 10000;
			// 	}else {
			// 		document.querySelector('.cookies-item').style.zIndex = -11000;
			// 	}
			// }
		}else {
			// setTimeout(() => {
		  	document.querySelector('.cookies-item-wrapper').style.display = 'none'
		  // }, 1000)
		}
	}
}
</script>

<style lang="scss">
@import '@css/variables.scss';


.cookies-item-wrapper {
	position: fixed;
	bottom: 0;
  // width: 100%;
  // left: 50%;
  // transform: translate(-50%, 0);
	z-index: 101;
	opacity: 0;
	transition: all .2s;
	transition-property: opacity, bottom;
	// display: none;
	// max-width: 
}

.cookies-item-wrapper.appear {
	opacity: 1;
	// z-index: 10;
	bottom: var(--s4);
}

.cookies-container {
	--flow-space-horizontal: var(--s2);
	--dgrid-item-col: 8;
	max-width: var(--dgrid-item-w);
	margin: 0 auto;
	background-color: var(--color-light);
	padding: var(--s2);
	overflow: hidden;
	border-radius: var(--radius);
}

.cookies-item {
	max-width: 55rem;
}

@media (max-width: $breakpoint-mb) {
	.cookies-item-wrapper.appear {
		bottom: 0;
		// --dgrid-item-col: 4;
		width: 100%;
		// margin: 0 auto;
		margin: 0 !important;
	}
	.cookies-container {
		bottom: 0;
		border-radius: unset;
		flex-direction: column;
	}
}

@media (max-width: $breakpoint-sm) {
	.cookies-buttons-container {
		flex-direction: column;
	}
	.cookies-item-wrapper.appear {
		bottom: 0;
	}
}
</style>
