<template>
  <button class="button-ghost text--button stack-horizontal" data-align="center" :class="{reversed, disabled}" @click="clickHandler">
    <span v-if="text">{{ text }}</span>
    <base-icon v-if="icon" :name="iconCurrent" :color-fill="disabled ? 'gray-medium' : 'black'"/>
  </button>
</template>

<script>
import validate from '@js/validations.js';
import button from '@mixins/button.js';

export default {
  name: 'BaseButtonGhost',
  mixins: [ button ],
  props: {
    text: String,
    reversed: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
/**
 * BUTTON STYLES
 * Style for the button component
 */

button.button-ghost {
  cursor: pointer;
  --flow-space-horizontal: var(--s0);

  span {
    color: var(--color-black);
  }

  svg {
    pointer-events: none; //to fix the "click-outside" case
  }

  &.disabled,
  &.disabled span {
    cursor: auto;
    color: var(--color-disabled);
  }

  &.reversed {
    flex-flow: row-reverse;
  }

  .icon svg {
    width: var(--s1);
  }

}
</style>