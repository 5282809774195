<template>
  <div class="button-base" v-click-outside="closeDropdown" :class="{opened: active, 'fit-width': fitWidth}">
    <button class="text--button" :class="[`button--${type} `, {disabled, 'button-dropdown':isDropdown, active}]" @click="clickHandler" >
      <span v-if="text">{{ text }}</span>
      <base-icon v-if="icon" :name="iconCurrent" :color-fill="iconColor"/>
    </button>
    <div v-if="dropdownActive" class="dropdown flow">
      <p v-for="option in dropdownList" @click="selectOption(option)">{{option.label}}</p>
    </div>
  </div>
</template>

<script>
import validate from '@js/validations.js';
import button from '@mixins/button.js';
import dropdown from '@mixins/dropdown.js';

export default {
  name: 'BaseButton',
  mixins: [ button, dropdown ],
  props: {
    text: String,
    type: {
      type: String,
      required: false,
      default: "primary",
      validator: (val) => {
        return ["primary", "secondary", "negative", "tertiary", "tertiary-negative"].indexOf(val) >= 0;
      }
    },
    fitWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    iconColor() {
      let rtn;
      if (!this.disabled) {
        rtn = this.type == "primary" ? "white" : "black";
      } else {
        rtn = "gray-light";
      }
      return rtn;
    }
  }
}
</script>

<style lang="scss">
/**
 * BUTTON STYLES
 * Style for the button component
 */

.button-base {
  position: relative;
  --button-dropdown-height: 2rem;
  min-width: 11.125rem;

  &.fit-width {
    width: 100%;
  }

  &:not(.fit-width) {
    max-width: fit-content;
  }

  &.opened {
    z-index: 2;
  }

  button {
    width:100%;
    padding: var(--s1) var(--s1);
    border: 1px solid var(--color-dark);
    cursor: pointer;
    border-radius: calc(var(--radius) / 2);
    color: var(--color-white);

    .icon svg {
      width: var(--s1);
      pointer-events: none;
    }

    &.button-dropdown {
      // width: 10rem;
      height: var(--button-dropdown-height);
      padding: 0 var(--s1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position:relative;
      z-index: 1;
      white-space: nowrap;
    }

    &.button--primary {
      background-color: var(--color-primary);
      border: 0;
      &:hover:not(.disabled) {
        background-color: var(--color-primary-125);
      }
    }
    &.button--secondary {
      background-color: var(--color-black-text);
      border: 0;
      &:hover:not(.disabled) {
        background-color: var(--color-gray-ultradark);
      }
    }
    &.button--negative {
      background-color: var(--color-white);
      border: 0;
      color: var(--color-black-text);
      &:hover:not(.disabled) {
        background-color: var(--color-gray-ultralight);
      }
    }
    &.button--tertiary {
      background-color: transparent;
      border: var(--border-thickness) solid var(--color-black-text);
      color: var(--color-black-text);
      &:hover,
      &.active {
        background-color: var(--color-ultralight);
      }
      &.disabled {
        background-color: transparent;
        color: var(--color-gray-light);
        border-color: var(--color-gray-light);
      }
    }
    &.button--tertiary-negative {
      background-color: transparent;
      border: 0.09375rem solid var(--color-white);
      color: var(--color-white);
      &:hover {
        background-color: var(--color-light);
      }
      &.disabled {
        background-color: transparent;
        color: var(--color-gray-medium);
        border-color: var(--color-gray-medium);
      }
    }

    &.disabled {
      background-color: var(--color-gray-ultralight);
      color: var(--color-gray-light);
    }
  }

  .dropdown {
    --flow-space: var(--s0);
    text-align: left;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: var(--s1);
    padding-top: calc(var(--button-dropdown-height) + var(--s1));
    background-color: var(--color-white);
    border-radius: calc(var(--radius) / 2) calc(var(--radius) / 2) 0 0;
    border: 0.09375rem solid var(--color-black-text);

    > p {
      cursor: pointer;
    }
  }
}
</style>