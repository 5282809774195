<template>
    <base-cs
      class="cs-sheet"
      v-bind="$props"
      :id="data.id"
      :title="data.title"
      :top="data.top"
      :bottom="data.bottom"
      :image="data.image"
      :skeleton="!loaded || skeleton"
      @clicked="clickHandler"
    />  
</template>

<script>
import cardSingle from '@mixins/cardSingle.js';

export default {
  name: 'CardSingleSheet',
  mixins: [ cardSingle ],
  data() {
    return {
      loaded: false,
      titleField: "title",
      topField: "category",
      bottomField: "institution",
      imageField: ["media_file", "thumb_file"] 
    }
  }
}
</script>
