export default {
  cards: [
    {
      id: 1,
      type: "card", 
      title: "titolo scheda", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 2,
      type: "card", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 1,
      type: "card", 
      title: "titolo scheda", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 2,
      type: "card", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
  ],
  voices: [
    {
      id: 1,
      type: "voice", 
      title: "titolo scheda", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 2,
      type: "voice", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 3,
      type: "voice", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
  ],
  list: [
    {
      id: 1,
      type: "list-vertical", 
      title: "titolo scheda", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 2,
      type: "list-vertical", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 3,
      type: "list-vertical", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
    {
      id: 4,
      type: "list-vertical", 
      title: "titolo scheda 1", 
      subtitleTop: "sottotitolo 1", 
      subtitleBottom: "sottotitolo 2", 
      image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
    },
  ],
  cardSets: [
    {
      title: "Storie",
      type: 'cardSet',
      subtitle: "200 storie",
      images: [
        {
          src: require('@assets/images/collages/GECA_cat_locali.png'),
          animations: `transform: translate(0, 5%);`
        },
        {
          src: require('@assets/images/collages/GECA_cat_locali2.png'),
          animations: `transform: translate(0, -5%);`
        }
      ]
    },
    {
      title: "Storie",
      type: 'cardSet',
      subtitle: "200 racconti",
      images: [
        {
          src: require('@assets/images/collages/GECA_NL.png'),
          animations: `transform: translate(0, 5%);`
        },
        {
          src: require('@assets/images/collages/GECA_NL2.png'),
          animations: `transform: translate(0, -5%);`
        },
        {
          src: require('@assets/images/collages/GECA_NL3.png'),
          animations: `transform: translate(0, 5%);`
        }
      ]
    },
    {
      title: "Storie",
      type: 'cardSet',
      subtitle: "200 eventi",
      images: [
        {
          src: require('@assets/images/collages/regioni/GECA_calabria1.png'),
          animations: `transform: translate(0, 5%);`
        },
        {
          src: require('@assets/images/collages/regioni/GECA_calabria2.png'),
          animations: `transform: translate(0, -5%);`
        }
      ]
    },
    {
      title: "Storie",
      type: 'cardSet',
      subtitle: "200 collezioni",
      images: [
        {
          src: require('@assets/images/collages/GECA_home.png'),
          animations: `transform: translate(0, 5%);`
        },
        {
          src: require('@assets/images/collages/GECA_home2.png'),
          animations: `transform: translate(0, -5%);`
        }
      ]
    },
    {
      title: "About",
      type: 'cardSet',
      subtitle: "200 collezioni",
      images: [
        {
          src: require('@assets/images/collages/GECA_home.png'),
          animations: `transform: translate(0, 5%);`
        },
        {
          src: require('@assets/images/collages/GECA_home2.png'),
          animations: `transform: translate(0, -5%);`
        },
        {
          src: require('@assets/images/collages/GECA_home3.png'),
          animations: `transform: translate(0, 5%);`
        }
      ]
    }
  ],
  categories: [
  ],
  databases: [
    {
      id: 1,
      type: "card-set", 
      title: "MiC",
      subtitle: "450 schede",
      image: require("@assets/images/database_mic.png")
    },
    {
      id: 2,
      type: "card-set", 
      title: "Granai della memoria",
      subtitle: "330 schede",
      image: require("@assets/images/database_granai_della_memoria.png")
    },
    {
      id: 3,
      type: "card-set", 
      title: "Casa Artusi",
      subtitle: "73 schede",
      image: require("@assets/images/database_casa_artusi.png")
    },
    {
      id: 4,
      type: "card-set", 
      title: "ICBSA",
      subtitle: "125 schede",
      image: require("@assets/images/database_icbsa.png")
    },
  ],
  stories: [
    {
      id: 1,
      type: "event", 
      title: "MiC",
      subtitle: "450 schede",
      image: require("@assets/images/database_mic.png")
    },
    {
      id: 2,
      type: "event", 
      title: "Granai della memoria",
      subtitle: "330 schede",
      image: require("@assets/images/database_granai_della_memoria.png")
    },
  ],
  events: [
    {
      id: 1,
      type: "event", 
      title: "Il tartufo e la regione marche ",
      subtitleTop: "7 Luglio 2022",
      subtitleBottom: "Teatro Angel Dal Foco, Pergola",
      image: require("@assets/images/event1.png")
    },
    {
      id: 2,
      type: "event",
      title: "Il Patrimonio Caseario della Basilicata",
      subtitleTop: "7 Luglio 2022",
      subtitleBottom: "Casa della Biodeiversità, Bra",
      image: require("@assets/images/event2.png")
    },
  ],
  qualityVoices: [
    {
      id: 1,
      type: "voice", 
      title: "La mungitura delle pecore in Sardegna",
      subtitleTop: "Uomo e animali",
      subtitleBottom: "Patrimonio Culturale - BDM",
      image: require("@assets/images/quality_voices1.png")
    },
    {
      id: 2,
      type: "voice",
      title: "I Raviolini del Plin",
      subtitleTop: "Ricette della tradizione",
      image: require("@assets/images/quality_voices2.png")
    },
  ],
  regions: [
    {
      title: "Calabria",
      type: 'cardSet',
      subtitle: "450 schede",
      images: [
        {
          src: require('@assets/images/collages/regioni/GECA_calabria1.png'),
          animations: `bottom`
        },
        {
          src: require('@assets/images/collages/regioni/GECA_calabria2.png'),
          animations: `top`
        }
      ]
    },
    {
      title: "Valle d'Aosta",
      type: 'cardSet',
      subtitle: "330 schede",
      images: [
        {
          src: require('@assets/images/collages/regioni/GECA_valdaosta1.png'),
          animations: `bottom`
        },
        {
          src: require('@assets/images/collages/regioni/GECA_valdaosta2.png'),
          animations: `top`
        }
      ]
    },
    {
      title: "Emilia Romagna",
      type: 'cardSet',
      subtitle: "450 schede",
      images: [
        {
          src: require('@assets/images/collages/regioni/GECA_emiliaromagna1.png'),
          animations: `bottom`
        },
        {
          src: require('@assets/images/collages/regioni/GECA_emiliaromagna2.png'),
          animations: `top`
        }
      ]
    },
    {
      title: "Umbria",
      type: 'cardSet',
      subtitle: "450 schede",
      images: [
        {
          src: require('@assets/images/collages/regioni/GECA_umbria1.png'),
          animations: `bottom`
        },
        {
          src: require('@assets/images/collages/regioni/GECA_umbria2.png'),
          animations: `top`
        }
      ]
    },
  ],
  storyData:  {
    id: 1,
    title: "Pellegrino Artusi e la Basilicata",
    subtitle: "Le diversità nella cucina casalinga",
    author: "Massimo Montanari - Casa Artusi",
    date: "Wed Mar 23 2022 11:19:27 GMT+0100 (Central European Standard Time)",
    body: [
      {type: 'subtitle', content: 'Dalle regioni del Sud proviene una parte significativa del patrimonio gastronomico italiano. Basti pensare alla pasta che diventa un segno primario dell’identità nazionale solo quando il Meridione si aggancia al Nord, nel processo di unificazione che nel 1861 vede nascere il Regno d’Italia.'},
      {type: 'video', content: 'https://culturalimentare.beniculturali.it/sources/9078/media/'},
      {type: 'text', content: 'La cucina italiana nasce e vive nel segno della diversità, legata ai prodotti dei singoli territori e alle ricette che ne sono l’espressione. Cucine semplici, non necessariamente povere, ma sempre legate alla cultura popolare. Come questi prodotti, queste cucine locali si siano messe in rete fino a costituire una cucina italiana perfettamente riconoscibile e dall’identità fortissima, è un miracolo che la storia ci ha regalato e che spetta a noi trasmettere. Ben vengano quindi i musei del cibo e le mostre sulla cultura alimentare, che ovunque in Italia si moltiplicano nella consapevolezza che tutto ciò serve alla valorizzazione – che non è semplice conservazione – di qualcosa che appartiene alla nostra cultura più sedimentata e che abbiamo imparato (finalmente…) a considerare come elemento costitutivo del patrimonio nazionale.'},
      {type: 'image', content: "https://culturalimentare.beniculturali.it/sources/3775/media/", settings: 'fullWidth'},
      {type: 'text', content: 'Sembra un miracolo. Si tratta in realtà di un meccanismo che porta il locale, il territoriale, a uscire da sé per entrare in un circuito di scambi (commerciali, certo, ma soprattutto di persone e di idee, cioè di cultura) che a poco a poco costruisce una realtà ampiamente condivisa nel paese Italia. Un posto speciale spetta alle verdure, punto di forza e di eccellenza dell’alimentazione mediterranea: se c’è qualcosa che veramente distingue la cultura gastronomica italiana da quelle d’oltralpe è la capacità di valorizzare le verdure in modo incredibilmente creativo e fantasioso. Cioè un’abilità tutta contadina di rapportarsi alla terra e ai suoi prodotti. Abilità contadina che però diventa di tutti, nel momento in cui anche l’alta cucina recepisce questi saperi, queste pratiche, rielaborandole e magari “nobilitandole” con aggiunte preziose.'},
      {type: 'image', content: "https://culturalimentare.beniculturali.it/sources/3758/media/"},
    ],
    institution_name: "UniSG",
    institution: 1,
    cover_image: "https://culturalimentare.beniculturali.it/sources/3875/media/",
    status: "published",
    featured: true,
    tags: [],
    partners: []
  },
  storyRelatedStories: [
    {
      id: 1,
      type: "event", 
      title: "Il frantoio 1200 anni fa",
      subtitle: "Massimo Montanari",
      image: require("@assets/images/database_mic.png")
    },
    {
      id: 2,
      type: "event", 
      title: "Biodeiversità e combinazioni",
      subtitle: "Mix, ICCD",
      image: require("@assets/images/database_granai_della_memoria.png")
    },
  ],
  storyRelatedItems: [
    {
      id: 1,
      type: "carousel", 
      title: "Il forno comunitario e la cottura del pane",
      subtitleTop: "Narrazioni orali",
      subtitleBottom: "Patrimonio Culturale - BDM, Regione FVG",
      image: require("@assets/images/database_mic.png")
    },
    {
      id: 2,
      type: "carousel", 
      title: "Cesto",
      subtitleTop: "Strumenti e tecniche",
      subtitleBottom: "Patrimonio Culturale - BDM, Regione FVG",
      image: require("@assets/images/database_mic.png")
    },
    {
      id: 3,
      type: "carousel", 
      title: "Abbadia di San Magno",
      subtitleTop: "Feste Popolari",
      subtitleBottom: "Granai della memoria UniSG",
      image: require("@assets/images/database_mic.png")
    },
    {
      id: 4,
      type: "carousel", 
      title: "Cestini per verdure",
      subtitleTop: "Archivi storici",
      subtitleBottom: "ICCD - Progetto PACI, MiC",
      image: require("@assets/images/database_mic.png")
    },
  ],
  imageSpoon: `${location.origin + process.env.BASE_URL}spoon.png`,
  imagesCardSetJson: [
    {
      src: `${location.origin + process.env.BASE_URL}spoon.png`,
      animations: `transform: translate(0, 5%);`
    },
    {
      src: `${location.origin + process.env.BASE_URL}cheese.png`,
      animations: `transform: translate(0, -5%);`
    }
  ],
  tags: ["Festa popolare", "cerimonie", "tecniche"],
}