import Vue from 'vue'
// import VueI18n from 'vue-i18n'
import i18n from '@js/i18nInstance.js';
import App from './App.vue'
import router from '@js/router.js'
import store from '@js/store/store.js'
import utils from '@js/utils.js';
import VueSkeletonLoader from 'skeleton-loader-vue';
import 'maplibre-gl/dist/maplibre-gl.css';

import VueMoment from 'vue-moment';
import Moment from 'moment';
import VueTypedJs from 'vue-typed-js';

import VueMeta from 'vue-meta'

Vue.use(VueTypedJs)

Vue.config.productionTip = false

// Vue.use(VueI18n);

Vue.use(VueMeta);

Vue.component('vue-skeleton-loader', VueSkeletonLoader);

// const i18n = new VueI18n({
//   locale: navigator.language.split('-')[0], // set locale
//   messages, // set locale messages
//   fallbackLocale: 'en'
// })

Moment.locale(i18n.locale);

// globally import Base components
const requireComponent = require.context(
  // Look for files in the current directory
  "./components",
  // Do not look in subdirectories
  false,
  // Only include "_base-" prefixed .vue files
  /Base[A-Z]\w+\.(vue)$/
);
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Remove the file extension from the end
  const componentName = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.use(VueMoment, { moment: Moment });

// for video playing
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  get: function(){
    return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
