const defaultMeta = {
  meta: {
    // 'og:title': (inserito automaticamente)
    // 'og:description': (inserito automaticamente)
    'og:type': 'article',
    'og:image': 'https://culturalimentare.beniculturali.it/vue/GECA_cover_web.jpg',

    'twitter:card': 'summary_large_image',
    // 'twitter:title': (inserito automaticamente)
    // 'twitter:description': (inserito automaticamente)
    // 'twitter:image': (inserito automaticamente)
  },
  it: {
    title: 'GeCA | Geoportale della Cultura Alimentare',
    meta: {
      author: 'GeCA | Geoportale della Cultura Alimentare',
      description: 'Geoportale della Cultura Alimentare. Il racconto, aperto e vivo, della tradizione italiana del cibo.',
    },
  },
  en: {
    title: 'GeCA | Geoportal of Food Culture',
    meta: {
      author: 'GeCA | Geoportal of Food Culture',
      description: 'Geoportal of Food Culture. The story, open and alive, of the Italian food tradition.',
    },
  },
};

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@views/PageMain.vue'),
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@views/ViewHome.vue')
      },
      {
        path: '/explore',
        name: 'explore',
        component: () => import('@views/ViewExplore.vue'),
        meta: {
          it: {
            title: 'Esplora',
            meta: {
              description: 'Esplora schede del Geoportale',
            },
          },
          en: {
            title: 'Explore',
            meta: {
              description: 'Explore sheets of Geoportale',
            },
          },
        },
      },
      {
        path: '/sheet/:slug',
        name: 'sheet',
        props: true,
        component: () => import('@views/ViewSheet.vue')
      },
      {
        path: '/stories',
        name: 'stories',
        component: () => import('@views/ViewStories.vue'),
        meta: {
          it: {
            title: 'Storie',
            meta: {
              description: 'Storie schede del Geoportale',
            },
          },
          en: {
            title: 'Stories',
            meta: {
              description: 'Stories sheets of Geoportale',
            },
          },
        },
      },
      {
        path: '/stories/:slug',
        name: 'story',
        props: true,
        component: () => import('@views/ViewStory.vue')
      },
      // { // TODO TEMPORARY
      //   path: '/stories/:id/test',
      //   name: 'mockupstory',
      //   props: { isMockup: true },
      //   component: () => import('@views/ViewStory.vue')
      // },
      {
        path: '/events',
        name: 'events',
        component: () => import('@views/ViewEvents.vue'),
        meta: {
          it: {
            title: 'Eventi',
            meta: {
              description: 'Eventi schede del Geoportale',
            },
          },
          en: {
            title: 'Events',
            meta: {
              description: 'Events sheets of Geoportale',
            },
          },
        },
      },
      {
        path: '/events/:slug',
        name: 'event',
        component: () => import('@views/ViewEvent.vue'),
        props: true
      },
      {
        path: '/institutions',
        name: 'institutions',
        component: () => import('@views/ViewInstitutions.vue')
      },
      {
        path: '/institutions/:slug',
        name: 'institution',
        component: () => import('@views/ViewInstitution.vue'),
        props: true
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@views/ViewInfo.vue'),
        props: {view: 'about'},
        meta: {
          it: {
            title: 'About',
            meta: {
              description: 'About schede del Geoportale',
            },
          },
          en: {
            title: 'About',
            meta: {
              description: 'About sheets of Geoportale',
            },
          },
        },
      },
      {
        path: '/topics',
        name: 'topics',
        component: () => import('@views/ViewTopics.vue'),
      },
      {
        path: '/takepart',
        name: 'takepart',
        component: () => import('@views/ViewInfo.vue'),
        props: {view: 'takepart'},
        meta: {
          it: {
            title: 'About',
            meta: {
              description: 'Prendi parte schede del Geoportale',
            },
          },
          en: {
            title: 'About',
            meta: {
              description: 'Take part sheets of Geoportale',
            },
          },
        },
      },
      {
        path: '/pressroom',
        name: 'pressroom',
        component: () => import('@views/ViewPressRoom.vue'),
        meta: {
          it: {
            title: 'Pressroom',
            meta: {
              description: 'Pressroom parte schede del Geoportale',
            },
          },
          en: {
            title: 'Pressroom',
            meta: {
              description: 'Pressroom part sheets of Geoportale',
            },
          },
        },
      },
      {
        path: '/pressroom/:id',
        name: 'presskit',
        component: () => import('@views/ViewPressKit.vue'),
        props: true
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@views/ViewHelp.vue')
      },
      {
        path: '/api',
        name: 'api',
        component: () => import('@views/ViewApi.vue')
      },
      {
        path: '/opendata',
        name: 'opendata',
        component: () => import('@views/ViewOpenData.vue')
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@views/ViewContacts.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@views/ViewSearch.vue')
      }
    ]
  },
  // {
  //   path: '/test',
  //   name: 'ViewTestComponents',
  //   component: () => import('@views/ViewTestComponents.vue')
  // },
  // {
  //   path: '/test_2',
  //   name: 'ViewTestComponents2',
  //   component: () => import('@views/ViewTestComponents2.vue')
  // },
  // {
  //   path: '/test_cardsingle',
  //   name: 'ViewTestComponents3',
  //   component: () => import('@views/ViewTestComponents3.vue')
  // },
  // {
  //   path: '/test_sheet/:id',
  //   name: 'ViewTestComponents4',
  //   component: () => import('@views/ViewTestComponents4.vue')
  // },
  // {
  //   path: '/test_story/:id',
  //   name: 'ViewTestComponents5',
  //   component: () => import('@views/ViewTestComponents5.vue')
  // },
  // {
  //   path: '/test_event/:id',
  //   name: 'ViewTestComponents6',
  //   component: () => import('@views/ViewTestComponents6.vue')
  // },
  // {
  //   path: '/test_institution/:id',
  //   name: 'ViewTestComponents7',
  //   component: () => import('@views/ViewTestComponents7.vue')
  // },
  // {
  //   path: '/test_cardset',
  //   name: 'ViewTestComponents8',
  //   component: () => import('@views/ViewTestComponents8.vue')
  // },
  // {
  //   path: '/test_cardset/:id',
  //   name: 'ViewTestComponents9',
  //   component: () => import('@views/ViewTestComponents9.vue')
  // }
];

module.exports = { routes, defaultMeta };