<template>

    <base-cg
      class="cg-database"
      v-bind="$props"
      :id="data.id"
      :title="data.title"
      :bottom="`${data.bottom} ${$tc('sheet', 2)}`"
      :image="data.image"
      :skeleton="!loaded || skeleton"
      @clicked="clickHandler"
      @img-loaded="singleImageLoadHandler"
    />    

</template>

<script>
import cardSet from '@mixins/cardSet.js';

export default {
  name: 'CardSetDatabase',
  mixins: [ cardSet ],
  data() {
    return {
      loaded: false,
      titleField: "name",
      bottomField: "sources",
      imageField: ["media"],
      imageType: "single"
    }
  }
}
</script>
