export default {
	BACKEND_URL: (process.env.NODE_ENV == "production" && !process.env.VUE_APP_FORCE_BACKEND_URL) ? location.origin : process.env.VUE_APP_BACKEND_URL,
	MOCKUP_BACKEND_URL: process.env.VUE_APP_MOCKUP_BACKEND_URL || process.env.VUE_APP_BACKEND_URL,
	PRODUCTION_BACKEND_URL: process.env.VUE_APP_MOCKUP_BACKEND_URL || process.env.VUE_APP_PRODUCTION_BACKEND_URL,
	API_PATH: process.env.VUE_APP_API_PATH || '',
	MAPTILER_TOKEN: process.env.VUE_APP_MAPTILER_TOKEN,
	MAPTILER_STYLE_URL: process.env.VUE_APP_MAPTILER_STYLE_URL || "https://api.maptiler.com/maps/5f8a3ca8-a9a1-4a33-86e9-15380de53c2f/style.json",
	MAP_BOUNDING_BOX: [[6.7499552751, 36.619987291], [18.4802470232, 47.1153931748]], // [sw, ne] -> [[lng, lat], [lng, lat]]
	MAP_CLUSTER_MAX_ZOOMIN: 20,
	EMAIL_URL: process.env.VUE_APP_EMAIL_URL || '',
	EMAIL_AUDIENCE_ID: process.env.VUE_APP_EMAIL_AUDIENCE_ID || '',
	EMAIL_API_KEY: process.env.VUE_APP_EMAIL_API_KEY || ''
}