<template>
	<div class="base-tag">
		<span class="text--body2">{{text}}</span>
	</div>
</template>

<script>
export default {
	name: 'BaseTag',
	props: {
		text: {
			type: String,
			required: true
		}
	}
}
</script>

<style lang="scss">
	.base-tag {
		position: relative;
			// width: 10rem;
	    height: var(--s3);
	    padding: 0 var(--s1);
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    // z-index: 1;
	    border: 0.09375rem solid var(--color-black-text);
	    border-radius: calc(var(--radius) / 2);
	    color: var(--color-black);
	    span {
	    	white-space: nowrap;
	    }
	}
</style>