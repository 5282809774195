/* 
	CMS MODULE

	State of the application concerning contents managed by the CMS
*/

import API from '@js/API.js';
import Vue from 'vue';
import _ from 'lodash';

export default {
	namespaced: true,
	state: {
		loading: false,
		events: [],
		institutions: [],
		stories: [],
		databases: [],
		topics: [],
		featured: {
			stories: [],
			databases: [],
			topics: [],
			events: []
		},
		featuredLoading: {
			stories: true,
			databases: true,
			topics: true,
			events: true			
		},
		pageSizeStories: 12,
		pageStories: 0,
		storiesQuery: {
			sorting: '-date',
			page_size: 12,
			page: 1
		},
		numStories: 0
	},
	mutations: {
		setLoading(state, loading) {
			state.loading = loading;
		},
		setList(state, {listName, items}) {
			Vue.set(state, listName, items);
		},
		setListItem(state, {listName, slug, item}) {
			let idx = state[listName].findIndex(el => el.slug == slug);
			if (idx >= 0) {
				Vue.set(state[listName], idx, { ...state[listName][idx], ...item })
			} else {
				state[listName].push(item);
			}
		},
		setListFeatured(state, {listName, items}) {
			Vue.set(state.featured, listName, items);
		},
		setListFeaturedLoading(state, {listName, loading}) {
			state.featuredLoading[listName] = loading;
		},
		parseQueryObjectStories(state, storiesQuery) {
			state.storiesQuery = storiesQuery
		},
		setNumStories(state, numStories) {
			state.numStories = numStories
		}
	},
	actions: {
		getListBasic({commit}, {listName, args}) {
			commit('setLoading', true);
			return API.backend[`get_${listName}`](args)
					.then((res) => {
						commit('setLoading', false);
						if (listName == 'stories') {
							res.json = res.json.filter(el => el.status == 'published');
						}
						return res.json;
					})
		},
		getListWithArgs({commit, dispatch}, {listName, args}) {
			console.log('getListWithArgs listName', listName, _.cloneDeep(args))
			dispatch("getListBasic", {listName, args})
				.then(res => {
					commit('setList', {listName, items: res});
				})
		},
		getList({ dispatch, commit }, listName) {
			dispatch("getListBasic", {listName, args: {}})
				.then(res => {
					commit('setList', {listName, items: res});
				})
		},
		getListItem({ commit }, {listName, slug}) {
			commit('setLoading', true);
			return API.backend[`get_${listName}_by_id`](slug)
					.then((res) => {
						commit('setListItem', {listName, slug, item: res});
						commit('setLoading', false);
						return res;
					})
		},
		getSheetsRelated({state, commit}, {listName, id, args}) {
            return API.backend.getSheetsSearch(args)
            		.then((res) => {
            			let data = res.data;
            			let related = [];
            			if (state[listName].length) {
            				let idx = state[listName].findIndex(el => el.id == id)
            				if (idx >= 0 && state[listName][idx]['sheets']) {
	            				related = state[listName][idx]['sheets']['items'];
	            			}
            			}
            			let sheets = {
            				count: data.results,
            				items: [...related, ...data.json]
            			}
            			commit('setListItem', {listName, id, item: {sheets}});
            		})
        },
        getListFeatured({state, commit}, listName) {
        	commit("setListFeaturedLoading", {listName, loading: true})
        	return API.backend[`get_${listName}`]({featured: true})
        			.then((res) => {
        				commit("setListFeatured", {listName, items: res.json});
        				commit("setListFeaturedLoading", {listName, loading: false})
        				// console.log("featured", listName, res)
        			})
        },
        parseQueryObjectStories({commit}, storiesQuery) {
        	commit('parseQueryObjectStories', storiesQuery)
        },
        setNumStories({commit}, numStories) {
        	commit('setNumStories', numStories)
        }
	},
	getters: {
		listItemById: state => (listName, id) => {
			let rtn = state[listName].find(el => el.id == id);
			return rtn || {};
		},
		listItemBySlug: state => (listName, slug) => {
			let rtn = state[listName].find(el => el.slug == slug);
			return rtn || {};
		},
		futureEvents: state => {
			return state.events.filter(el => {
				let now = new Date();
				let date = new Date(el.date);
				return date >= now;
			})
		},
		pastEvents: state => {
			return state.events.filter(el => {
				let now = new Date();
				let date = new Date(el.date);
				return date < now;
			})
		},
		listFeatured: state => listName => {
			return state.featured[listName];
		},
		topicByName: state => name => {
			return state.topics.find(el => el.name == name);
		},
		loadingByListName: state => listName => state.featuredLoading[listName],
		numPagesStories: state => Math.ceil(state.numStories / state.pageSizeStories),
		currentPageStories: state => parseInt(state.storiesQuery.page) || 1
	}
}