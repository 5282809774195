<template>
  <base-card-single
    :id="data.id"
    :slug="data.slug"
    :title="data.title"
    :subtitle-top="data.subtitleTop"
    :subtitle-bottom="data.subtitleBottom"
    :data-type="data.dataType"
    :image="data.image"
    :is-popup="isPopup"
    :key="data.id"
    v-bind="$props"
    @clicked="$emit('clicked', {id: data.id, slug: data.slug})"
  />
</template>

<script>
import globals from '@js/globals.js';
import utils from '@js/utils.js';
import _ from 'lodash';

export default {
  name: 'BaseCardSingleSheet',
  props: {
    sheet: {
      type: Object,
      required: true
    },
    showTypeFile: {
      type: Boolean,
      default: false  
    },
    type: String,
    isPopup: {
    	type: Boolean,
    	default: false
    }
  },
  computed: {
    data() {
      let rtn = {};
      if (this.sheet.id) {

        let thumb = (!this.sheet.data_type || ['video', 'audio', 'micronarrazione'].indexOf(this.sheet.data_type.toLowerCase()) >= 0) ? "thumb_file" : "media_file";

        rtn["id"] = this.sheet["id"];
        rtn["slug"] = this.sheet["slug"];
        rtn["title"] = this.sheet["title"];
        rtn["subtitleTop"] = this.sheet["category"];
        rtn["subtitleBottom"] = this.isPopup ? '' : this.sheet["source_db"];
        rtn["image"] = this.sheet[thumb] ? utils.parseMediaUrl(this.sheet[thumb]) : '';
        rtn["dataType"] = this.sheet["data_type"];
      }
      // console.log('sheet inside base-card-single', _.cloneDeep(rtn))
      return rtn;
    }
  }
}
</script>
