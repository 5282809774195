<template>
  <base-cs
    class="cs-story"
    v-bind="$props"
    :id="data.id"
    :title="data.title"
    :top="data.top"
    :bottom="data.bottom"
    :image="data.image"
    :skeleton="!loaded || skeleton"
    @clicked="clickHandler"
  />
</template>

<script>
import cardSingle from '@mixins/cardSingle.js';

export default {
  name: 'CardSingleStory',
  mixins: [ cardSingle ],
  props: {
    imgAspectRatio: {
      type: String,
      default: "3/2",
    },
    size: {
      type: String,
      default: "medium",
    },
    skeletonLines: {
      type: Number,
      default: 2,
      required: false
    }
  },
  data() {
    return {
      titleField: "title",
      bottomField: "author",
      imageField: ["media_file", "cover_image", "thumb_file"] 
    }
  }
}
</script>
