/* 
    SHEET STORE MODULE

    State of the application concerning sheets
*/
import Vue from 'vue';
import API from '@js/API.js';
import utils from '@js/utils.js';
import 'lodash';

const localStorageDurationMs = 1209600000; // two weeks

export default {
    namespaced: true,
    state: {
        results:    [], // list of sheet objects
        page:       [], // list of sheet objects
        selected:   [], // list of sheet objects
        all: [],
        pageSize: 27,
        numResults: 0,
        numPages: 20, //TODO MOCKUP
        startId: 3800, //TODO MOCKUP
        filterOptions: {},
        latestFilterApplied: undefined,
        loading: {
            filters: false,
            page: false,
            results: false,
            featured: false
        },
        mapBbox: [],
        featured: [],
        updateSheetsStorageEnabled: true,
        expireSheetsStorageEnabled: false
    },
    mutations: {
        setList(state, {listName, objs}) {
            Vue.set(state, listName, objs);
            // console.log("setList", listName, state[listName])
        },
        clearList(state, {listName}) {
            Vue.set(state, listName, []);
            // console.log("clearList", listName)
        },
        setFilterOptions(state, {filter, options}) {
            Vue.set(state.filterOptions, filter, options);
            // console.log("filter option ", filter, "set to", options)
        },
        setLatestFilterApplied(state, name) {
            state.latestFilterApplied = name;
        },
        setLoading(state, {type, status}) {
            state.loading[type] = status;
            // console.log("loading state", type, "went", status)
        },
        setMapBBox(state, data) {
            let coords = data.map(el => [el.geodata_longitude, el.geodata_latitude]);
            let bbox = utils.getBboxByCoords(coords);
            // console.log("setMapBBox", bbox)
            Vue.set(state, 'mapBbox', bbox);
        },
        setNumResults(state, num) {
            state.numResults = num;
        },
        setFeatured(state, items) {
            Vue.set(state, "featured", items);
        }
    },
    actions: {
        getSheets({state, commit, dispatch}, args) {
            return API.backend.getSheetsSearch(args)
                .then(res => {
                    let data = res.data;
                    // TODO WARNING: this is a useless computation to perform,
                    // but there is a problem with :first-letter pseudo selector
                    // on non block elements (required for line clamping)
                    data.json.forEach(el => {
                        if (el.title && el.title.length) el.title = el.title[0].toUpperCase() + el.title.slice(1);
                    })
                    return res.data;
                });
        },
        getSheetsPage({state, commit, dispatch}, args) {
            commit('setLoading', {type: 'page', status: true})
            commit('setList', {listName: 'page', objs: []});
            let argsPage = {}
            Object.assign(argsPage, args);
            argsPage = {...argsPage, page_size: state.pageSize.toString()}
            dispatch('getSheets', argsPage)
                .then(res => {
                    // console.log("getSheets from getSheetsPage then: ",res, res.data)
                    commit('setNumResults', res.results);
                    commit('setList', {listName: 'page', objs: res.json});
                    commit('setLoading', {type: 'page', status: false})
                })
        },

        getSheetsShort({state, commit, dispatch}, args) {
            commit('setLoading', {type: 'results', status: true})
            const argsToTrim = ['page', 'page_size'];
            const argsToIgnoreForCache = [...argsToTrim, 'field', 'sorting'];
            let noFilters = true;
            const alreadyDownloadedAll = !!state.all.length;

            // compute if there are filters applied, in order to store cache (see below)
            Object.keys(args).filter(el => !argsToIgnoreForCache.includes(el)).forEach(key => {
                if ((args[key] && (!Array.isArray(args[key]) || args[key].length))) {
                    noFilters = false;
                }
            })

            let argsShort = {}
            Object.assign(argsShort, args);
            argsShort = {...argsShort, short:true}
            argsToTrim.forEach(el => {
                delete argsShort[el]
            })
            argsShort['page_size'] = 100000;
            // cache the response just if there are no filter applied
            if (noFilters && !alreadyDownloadedAll) {
                // NO filters applied AND NOT downloaded already, let's validate localStorage...
                if (validateLocalStorage(state.expireSheetsStorageEnabled)) {
                    // localStorage is valid, let's use it
                    let data = JSON.parse(localStorage.getItem('geca_allsheets'));
                    commit('setNumResults', data.length);
                    commit('setList', {listName: 'results', objs: data});
                    commit('setList', {listName: 'all', objs: data});
                    commit('setLoading', {type: 'results', status: false});

                    // meanwhile, download data via API and check for updates
                    if (state.updateSheetsStorageEnabled) dispatch('updateSheetsStorage', argsShort);                 
                } else {
                    // localStorage is NOT valid, let's get data from API
                    dispatch('getSheets', argsShort)
                        .then(res => {
                            let data = res.json;
                            // console.log("getSheets from getSheetsShort then: ", res.data)
                            commit('setNumResults', data.length);
                            commit('setMapBBox', data.filter(el => el.geodata_latitude && el.geodata_longitude))
                            commit('setList', {listName: 'results', objs: data});
                            commit('setList', {listName: 'all', objs: data});
                            localStorage.setItem('geca_allsheets', JSON.stringify(data));
                            localStorage.setItem('geca_updatedat', JSON.stringify(new Date()));
                            commit('setLoading', {type: 'results', status: false})
                        })
                }

            } else if (noFilters && alreadyDownloadedAll) {
                // NO filters applied AND ALREADY downloaded, let's use data from RAM (store)
                commit('setList', {listName: 'results', objs: state.all});
                commit('setLoading', {type: 'results', status: false})
            } else {
                // some filters are applied: ALWAYS download data via API
                dispatch('getSheets', argsShort)
                    .then(res => {
                        let data = res.json;
                        commit('setNumResults', data.length);
                        commit('setMapBBox', data.filter(el => el.geodata_latitude && el.geodata_longitude))
                        commit('setList', {listName: 'results', objs: data});
                        commit('setLoading', {type: 'results', status: false})
                    })
            }
        },
        getSheetById({commit}, id) {
            return API.backend.getSheetById(id)
                .then(res => {
                    let data = res.data.json;
                    // commit('setSheetData', data);
                    return data;
                });
        },
        getSheetBySlug({commit}, slug) {
            return API.backend.getSheetBySlug(slug)
                .then(res => {
                    let data = res.data.json;
                    // commit('setSheetData', data);
                    return data;
                });
        },
        getSheetsSelected({state, commit, dispatch}, ids) {
            commit('clearList', {listName: 'selected'});
            let rtn = [];
            let total = ids.length;
            for (let i=0; i < total; i++) {

                let stored = state.page.find(el => el.id == ids[i])

                if (stored) {
                    rtn.push(stored);
                    if (rtn.length == total) {
                        commit('setList', {listName: 'selected', objs: rtn});
                    }                    
                } else {
                    dispatch('getSheetById', ids[i])
                        .then((res) => {
                            rtn.push(res);
                            if (rtn.length == total) {
                                commit('setList', {listName: 'selected', objs: rtn});
                            }
                        });
                }

            }
        },
        getFiltersExlude({state, commit, dispatch}, {queryObj, exclude}) {
            commit("setLoading", {type: 'filters', status: true})
            return API.backend.getFilters({})
                .then( res => {
                    try {
                        // let data = res.data.json;
                        // Object.keys(data).forEach( filter => {
                        //     // if (filter == exclude) {
                        //     if (filter != 'rights') { //TODO - DATABASE NEEDS CORRECTIONS
                        //         commit('setFilterOptions', {
                        //             filter,
                        //             options: data[filter].filter(el=>el && el?.length)
                        //         })                                
                        //     }
                        // })
                        dispatch('getFilters', {queryObj, exclude});
                    } catch (err) {
                        console.error("getFiltersExlude error", err)
                    }
                })            
        },
        getFilters({ state, commit }, {queryObj, exclude}) {
            commit("setLoading", {type: 'filters', status: true})
            return API.backend.getFilters(queryObj)
                .then( res => {
                    // console.log("getFilters res:", res.data.json)
                    try {
                        let data = res.data.json;
                        Object.keys(data).forEach( filter => {
                            // if (state.latestFilterApplied != filter && exclude != filter) {
                                // if (filter != 'rights') //TODO - DATABASE NEEDS CORRECTIONS
                                commit('setFilterOptions', {
                                    filter,
                                    options: data[filter].filter(el=>el?.length)
                                })                                
                            // }
                        })
                        commit("setLoading", {type: 'filters', status: false})
                    } catch (err) {
                        console.error("getFilters error", err)
                    }
                })
        },
        getFeaturedSheets({state, commit, dispatch}) {
            commit("setLoading", {type: 'featured', status: true})
            return dispatch("getSheets", {featured: true})
                    .then((res) => {
                        commit("setLoading", {type: 'featured', status: false})
                        commit("setFeatured", res.json);
                    });
        },
        updateSheetsStorage({ state, commit, dispatch }, argsShort) {
            dispatch('getSheets', argsShort)
                .then(res => {
                    const newData = res.json;
                    const oldData = state.all;
                    if (!_.isEqual(newData, oldData)) {
                        // there are updates avilable, store in localStorage
                        localStorage.setItem('geca_allsheets', JSON.stringify(newData));
                        localStorage.setItem('geca_updatedat', JSON.stringify(new Date()));
                    } // else localStorage is already up to date 
                })

        }
    },
    getters: {
        sheetsByList: state => listName => {
            return state[listName];
        },
        sheetSelected: (state, getters) => {
            let sel = getters.sheetsByList('selected');
            return sel[0] ? sel[0] : {};
        },
        sheetById: state => id => {
            let rtn = state.page.find(el => el.id == id);
            return rtn ? rtn : {};
        },
        filterOptionsByKey: state => key => {
            let filters = state.filterOptions[key];
            return filters ? filters : [];
        },
        loadingByType: state => type => state.loading[type],
        numPages: state => Math.ceil(state.numResults / state.pageSize)
    }
}

function validateLocalStorage(doExpire) {
    let rtn = true;
    let data = localStorage.getItem('geca_allsheets');
    if (doExpire) {
        let updatedat = localStorage.getItem('geca_updatedat');
        if (!updatedat || !data || (new Date() - new Date(JSON.parse(updatedat))  >= localStorageDurationMs)) rtn = false;
    } else {
        if (!data) rtn = false;
    }
    // console.log("localStorage is ", rtn ? "valid" : "invalid");
    return rtn;
}