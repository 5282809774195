<template>
	<div class="relative animated-images-container"
		@mouseenter="mouseInteraction($event, true)" 
		@mouseleave="mouseInteraction($event, false)" 
	>
		<img v-for="(img, i) in images" 
			class="animated-images abs-fill"
			:src="img.src"
			:alt="img.alternativeText"
			:style="styleImages[i]"
		>
	</div>
</template>

<script>
import _ from 'lodash'
export default {
	name: 'BaseAnimatedImage',
	props: {
		images: {
			type: Array,
			required: true
		},
		mouseInside: {
			type: Boolean,
			required: false
		}
	},
	data() {
		return {
			innerMouse: false
		}
	},
	computed: {
		mouseInsideComputed() {
			return this.mouseInside == undefined ? this.innerMouse : this.mouseInside
		},
		styleImages() {
			let styles = []

			this.images.forEach((image, i) => {
				switch(image.animations) {
					case 'bottom':
						styles.push(`width: 100%; height: auto; left: 0px; top: -10px; ${this.innerMouse ? 'transform: translate(0, 10px)' : ''}`)
						break;
					case 'top':
						styles.push(`width: 100%; height: auto; left: 0px; top: 10px; ${this.innerMouse ? 'transform: translate(0, -10px)' : ''}`)
						break;
					case 'right':
						styles.push(`width: 100%; height: auto; left: -10px; top: -10px; ${this.innerMouse ? 'transform: translate(10px, 0)' : ''}`)
						break;
					case 'left':
						styles.push(`width: 100%; height: auto; right: -10px; bottom: -10px; ${this.innerMouse ? 'transform: translate(-10px, 0)' : ''}`)
						break;
				}
			});
			return styles;
    },
	},
	methods: {
		mouseInteraction(event, value) {
			this.innerMouse = value;
		}
	},
}
</script>

<style lang="scss">
	@import '@css/variables.scss';
	.animated-images-container {
		width: 100%;
		aspect-ratio: 1/1;
	}
	.animated-images {
		width: calc(100% + 20px); 
		height: calc(100% + 20px);
		background-repeat: no-repeat;
		// filter: grayscale(100);
		mix-blend-mode: multiply;
		transition: all $transition-speed;
	}
</style>
